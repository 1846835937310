// Login component
import { AssistiveText, Button } from "@bigtincan/react-design-system";
import React, { Component } from "react";
import { hubService } from "../../hubService";

interface ILoginProps {
    displayAuthError?: boolean;
    error?: string
}

class Login extends Component<ILoginProps, {}> {

    async handleLoginClick() {
        let signInURL = await hubService.getAdminSignInURL("US") as string;

        if (signInURL) {
            window.location.href = signInURL;
        }
    }

    render() {
        return (
            <div style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                left: "25%",
                top: "40%",
                textAlign: "center"
            }}>
                <h3>Looks like you are not signed in, please sign in to get started.</h3>
                <Button onClick={this.handleLoginClick} color='tint' hierarchy='primary' size='xlarge'>
                    Sign in with Bigtincan
                </Button>
                {this.props.displayAuthError &&
                    <AssistiveText variant='negative' message={`${this.props.error === 'usernotallowed' ? "Looks like you don't have access to the admin portal, please reach out to the Teams administrator for assistance." : "Error occurred in authentication, please try again"}`} />
                }
            </div>
        );
    }
}

export default Login;
