/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import * as React from "react";
import * as microsoftTeams from '@microsoft/teams-js';
import { hubService } from "./hubService";
import { authentication } from "@microsoft/teams-js";

const moment = require("moment");

export const checkUserAuth = async (region?: string) => {
    return await new Promise((resolve, reject) => {
        microsoftTeams.app.initialize().then(async () => {
            let context = await microsoftTeams.app.getContext();
            let key = "BTC";
            let azureAdObjectId = context.user?.id as string;
            let tenantId = context.user?.tenant?.id as string;
            let storage = JSON.parse(localStorage.getItem(key) as string);

            if (storage) {

                let currentDate = new Date();
                let convertedDate = moment(currentDate).format("x");
                let idAccessToken = storage.idAccessToken;
                let tokenExpiry = moment(storage.expiresIn).format("x");

                if (tokenExpiry > convertedDate) { // Check if the access token is expired
                    let userInfo: any = await hubService.getUserInfo(idAccessToken, tenantId, azureAdObjectId, region).catch((error) => {
                        resolve(false);
                    });
                    if (userInfo) {
                        let profileInfo = {
                            first_name: userInfo.first_name,
                            last_name: userInfo.last_name,
                            email: userInfo.email,
                        }
                        localStorage.setItem("userInfo", JSON.stringify(profileInfo));
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                } else {
                    let idRefreshToken = storage.idRefreshToken;
                    microsoftTeams.authentication.getAuthToken().then((response: any) => {
                        hubService.refreshClientSideTokens(tenantId, azureAdObjectId, response, idRefreshToken).then(async (response: any) => {
                            let authInfo = {
                                accessToken: response.btch_access_token,
                                refreshToken: response.btch_refresh_token,
                                expiresIn: response.btch_access_token_expiry,
                                idRefreshToken: response.btcid_refresh_token,
                                idAccessToken: response.btcid_access_token,
                                idExpiresIn: response.btcid_access_token_expiry
                            }
                            localStorage.setItem("BTC", JSON.stringify(authInfo));
                            let refreshedAccessToken = response.btch_access_token;
                            if (refreshedAccessToken) {
                                let userInfo: any = await hubService.getUserInfo(response.btcid_access_token, tenantId, azureAdObjectId, region).catch((error) => {
                                    resolve(false);
                                });
                                if (userInfo) {
                                    let profileInfo = {
                                        first_name: userInfo.first_name,
                                        last_name: userInfo.last_name,
                                        email: userInfo.email,
                                    }
                                    localStorage.setItem("userInfo", JSON.stringify(profileInfo));
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            } else {
                                resolve(false)
                            }
                        }).catch((error: any) => {
                            resolve(false)
                        });
                    }).catch((error: any) => {
                        resolve(false)
                    });
                }
            } else {
                authentication.getAuthToken().then((result: string) => {
                    let token = result;
                    hubService.getUserLoginSession(token, tenantId, azureAdObjectId).then(async (response: any) => {
                        if (response) {
                            localStorage.setItem("BTC", JSON.stringify(response.authData));
                            let accessToken = response.authData.idAccessToken;
                            if (accessToken) {
                                let userInfo: any = await hubService.getUserInfo(accessToken, tenantId, azureAdObjectId, region).catch((error) => {
                                    resolve(false);
                                });
                                if (userInfo) {
                                    let profileInfo = {
                                        first_name: userInfo.first_name,
                                        last_name: userInfo.last_name,
                                        email: userInfo.email,
                                    }

                                    localStorage.setItem("userInfo", JSON.stringify(profileInfo));
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            } else {
                                resolve(false)
                            }
                        } else {
                            resolve(false);
                        }
                    }).catch((error: any) => {
                        resolve(false);
                    });
                }).catch((error: any) => {
                    resolve(false)
                });
            }
        });
    });
}