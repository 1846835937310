/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import * as React from "react";
import * as microsoftTeams from '@microsoft/teams-js';
import { hubService } from "./hubService";
import { authentication } from "@microsoft/teams-js";

const moment = require("moment");

export const checkAdminUserAuth = async () => {
    return await new Promise((resolve, reject) => {

        let storage = JSON.parse(localStorage.getItem("BTC_ADMIN") as string);

        if (storage) {

            let currentDate = new Date();
            let convertedDate = moment(currentDate).format("x");
            let idAccessToken = storage.btcid_access_token;
            let tokenExpiry = moment(storage.btcid_access_token_expiry).format("x");

            if (tokenExpiry > convertedDate) { // Check if the access token is expired

                let userInfo = JSON.parse(localStorage.getItem("BTC_ADMIN_USER") as string);

                if (userInfo) {
                    resolve(true);
                } else {
                    resolve(false);
                }

            } else {

                let idRefreshToken = storage.btcid_refresh_token;
                hubService.refreshAdminClientTokens(idAccessToken, idRefreshToken).then(async (response: any) => {
                    let userAuthData = response.userAuthData;
                    let userInfo = response.userInfo;

                    if (userInfo && userAuthData) {
                        localStorage.setItem("BTC_ADMIN", JSON.stringify(userAuthData));
                        localStorage.setItem("BTC_ADMIN_USER", JSON.stringify(userInfo));
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch((error: any) => {
                    localStorage.clear();
                    resolve(false);
                });
            }
        } else {
            resolve(false);
        }
    });
}