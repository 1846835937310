/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import * as React from "react";
import * as microsoftTeams from '@microsoft/teams-js';
import { hubService } from "../../hubService";
import { authentication } from "@microsoft/teams-js";
import MeetingHome from "../../containers/MeetingHome";
import { Link } from '@fluentui/react-components';
import {
    Communication,
    CommunicationOptions,
    ICommunicationFields
} from "@fluentui/react-teams";
import AlertError from "../Alert/Alert";
import Home from "../../containers/Home";

interface LoginState {
    defaultRegion: string;
    isLoading: boolean;
    firstName: string;
    lastName: string;
    userEmail: string;
    region: string;
    userAuthenticated: boolean;
    displayAuthError: boolean;
    appClientId: string;
    isMeeting: boolean;
}

export class Login extends React.Component<{}, LoginState> {
    constructor(props: any) {
        super(props);
        this.signInWithBigtincan = this.signInWithBigtincan.bind(this);
        // this.signInWithMicrosoft = this.signInWithMicrosoft.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.state = {
            defaultRegion: "",
            userAuthenticated: false,
            isLoading: false,
            firstName: "",
            lastName: "",
            userEmail: "",
            region: "",
            displayAuthError: false,
            appClientId: "",
            isMeeting: false
        };
    }

    async componentDidMount() {
        microsoftTeams.app.initialize().then(async () => {
            let defaultRegion;
            let appClientId;
            let context = await microsoftTeams.app.getContext();
            let tenantId = context.user?.tenant?.id as string;
            let meetingId = context.meeting?.id;

            if (meetingId) {
                this.setState({
                    isMeeting: true
                });
            }

            const tenantSettings = await hubService.getTenantSettings(tenantId) as any;

            if (tenantSettings) {
                if (tenantSettings["BTC_REGION"]) {
                    defaultRegion = tenantSettings["BTC_REGION"];
                    this.setState({
                        defaultRegion
                    });
                }
                if (tenantSettings["APP_CLIENT_ID"]) {
                    appClientId = tenantSettings["APP_CLIENT_ID"];
                    this.setState({
                        appClientId
                    });
                }
            }
        })
    }

    /* TO BE ADDED LATER - MICROSOFT AUTHENTICATION
    
    async signInWithMicrosoft() {
        this.setState({
            isLoading: true
        });
        const authenticationParams = {
            url: `${window.location.origin}/app/auth-start?appClientId=${this.state.appClientId}`,
            width: 600,
            height: 535,
        }
        authentication.authenticate(authenticationParams).then(async (result: any) => {
            let data = JSON.parse(result);

            if (data) {
                let authData = data.authData;
                let userInfo = data.userInfo;
                localStorage.setItem("BTC", JSON.stringify(authData));
                if (userInfo) {
                    localStorage.setItem("userInfo", JSON.stringify(userInfo));
                    this.setState({
                        isLoading: false,
                        userAuthenticated: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        userAuthenticated: false,
                    });
                }
            } else {
                this.setState({
                    isLoading: false,
                    userAuthenticated: false,
                });
            }
        }).catch((error: any) => {
            this.setState({
                displayAuthError: true,
                userAuthenticated: false,
                isLoading: false
            });
        });
    };
    */

    signInWithBigtincan = async () => {
        authentication.authenticate({
            url: `${window.location.origin}/app/auth-start-bigtincan?region=${this.state.defaultRegion}`,
            height: 800,
            width: 600,
        }).then(async (result) => {
            let data = JSON.parse(result);
            if (data) {
                let authData = data.authData;
                let userInfo = data.userInfo;
                localStorage.setItem("BTC", JSON.stringify(authData));
                if (userInfo) {
                    localStorage.setItem("userInfo", JSON.stringify(userInfo));
                    this.setState({
                        isLoading: false,
                        userAuthenticated: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        userAuthenticated: false,
                    });
                }
            } else {
                this.setState({
                    displayAuthError: true,
                    isLoading: false,
                    userAuthenticated: false
                });
            }
        }).catch((reason) => {
            this.setState({
                displayAuthError: true,
                isLoading: false,
                userAuthenticated: false
            });
        });
    }

    learnMore = async (event: any) => {
        let userResponse = window.confirm("This link will take you to an external site. Do you want to proceed?");
        if (userResponse) {
            window.open("https://help.bigtincan.com/hc/en-us/articles/20674333286413-Bigtincan-App-for-Microsoft-Teams");
        }
    }

    handleAction(event: any) {
        if (event.target === "signInWithBigtincan") {
            this.signInWithBigtincan();
        }
        // else if (event.target === "signInWithMicrosoft") {
        //     this.signInWithMicrosoft();
        // }
        else if (event.target === "learnMore") {
            this.learnMore(event);
        }
    };

    contactUs(event: any) {
        event?.preventDefault();
        let userResponse = window.confirm("This link will take you to an external site. Do you want to proceed?");
        if (userResponse === true) {
            window.open("https://www.bigtincan.com/contact/");
        }
    }

    public render() {

        let frameContext = microsoftTeams.app.getFrameContext() as string;
        const welcomeMeetingCommFields: ICommunicationFields = {
            title: `Welcome to Bigtincan! We’re glad you’re here.`,
            desc: `Bigtincan for Meetings allows you to search and present content in a meeting.`,
            actions: {
                primary: {
                    label: "Sign In with Bigtincan",
                    target: "signInWithBigtincan"
                },
                tertiary: {
                    label: "Learn more",
                    target: "learnMore"
                },
            },
        };

        const welcomeCommFields: ICommunicationFields = {
            title: `Welcome to Bigtincan! We’re glad you’re here.`,
            desc: `Bigtincan app for Microsoft Teams allows you to search, share, preview Bigtincan content and receive notifications within Microsoft Teams. Please sign-in to get started. `,
            actions: {
                primary: {
                    label: "Sign In with Bigtincan",
                    target: "signInWithBigtincan"
                },
                tertiary: {
                    label: "Learn more",
                    target: "learnMore"
                },
            },
        };

        if (!this.state.userAuthenticated) {
            if (this.state.isMeeting) {
                return (
                    <div style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        position: "fixed"
                    }} >
                        <Communication
                            option={CommunicationOptions.Welcome}
                            fields={welcomeMeetingCommFields}
                            onInteraction={this.handleAction}
                        />
                        {this.state.displayAuthError &&
                            <div>
                                <AlertError />
                            </div>
                        }
                        <div style={{ textAlign: "center" }}>
                            You need an active Bigtincan account to use this app. Don't have a Bigtincan account ? <Link onClick={(e) => this.contactUs(e)} > Contact Us</Link>
                        </div>
                    </div >
                )
            } else {
                return (
                    <div style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        position: "fixed"
                    }} >
                        <Communication
                            option={CommunicationOptions.Welcome}
                            fields={welcomeCommFields}
                            onInteraction={this.handleAction}
                        />
                        {this.state.displayAuthError &&
                            <div>
                                <AlertError />
                            </div>
                        }
                        <div style={{ textAlign: "center" }}>
                            You need an active Bigtincan account to use this app. Don't have a Bigtincan account ? <a href="https://www.bigtincan.com/contact/" target="_blank" onClick={(e) => this.contactUs(e)}>
                                Click here
                            </a>
                        </div>
                    </div>
                )
            }
        }
        else {
            if (this.state.isMeeting) {
                return (<MeetingHome />)
            } else {
                return (<Home />)
            }
        }
    }
}