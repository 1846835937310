/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React, { Component } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { hubService } from "../../hubService";
import { Card, CloseIcon, Dialog, FormButton, Alert, FormDropdown } from "@fluentui/react-northstar";
import copy from 'copy-to-clipboard';
import { authentication } from "@microsoft/teams-js";
import {
    Form,
    FormInput,
    FormCheckbox,
    FormDatepicker,
} from '@fluentui/react-northstar'
import { Communication, CommunicationOptions, ICommunicationFields } from '@fluentui/react-teams';
import AlertError from "../../components/Alert/Alert";
import { checkUserAuth } from "../../auth";
import Loader from "../Loader";

const moment = require("moment");

interface IShareState {
    error: boolean,
    file: any,
    expireLinkAfterChecked: boolean,
    includeAccessFormChecked: boolean,
    externalLink: string,
    expireInDays: number,
    includeAccessFormSetting: string,
    canEditLinkExpiryDate: boolean,
    addLeadFormLinkDefault: boolean,
    defaultLinkExpiryDuration: string,
    linkTitle: string,
    userAuthenticated: boolean,
    displayLinkLoader: boolean,
    dialogOpen: boolean,
    isLoading: boolean,
    canCreateHubshareLink: boolean,
    displayAlert: boolean,
    isMobile: boolean,
    displayAuthError: boolean,
    authError: boolean,
    formTemplatesEnabled: boolean,
    leadFormList: any[],
    selectedLeadFormId: number,
    accessFormArray: any[],
    selectedLeadFormName: string
}

interface IShareProps {

}

class Share extends Component<IShareProps, IShareState>{
    constructor(props: any) {
        super(props);
        this.state = {
            error: false,
            canCreateHubshareLink: true,
            file: {},
            includeAccessFormChecked: false,
            expireLinkAfterChecked: false,
            externalLink: "",
            expireInDays: 0,
            includeAccessFormSetting: "",
            canEditLinkExpiryDate: true,
            addLeadFormLinkDefault: false,
            defaultLinkExpiryDuration: "0",
            linkTitle: "Shared via Microsoft Teams",
            userAuthenticated: false,
            displayLinkLoader: false,
            dialogOpen: false,
            isLoading: false,
            displayAlert: false,
            isMobile: false,
            displayAuthError: false,
            authError: false,
            formTemplatesEnabled: false,
            leadFormList: [],
            selectedLeadFormId: 0,
            accessFormArray: [],
            selectedLeadFormName: ""
        };
    }

    async componentDidMount() {
        microsoftTeams.app.initialize().then(async () => {

            this.setState({
                isLoading: true
            });

            let key = "BTC";
            let storage = JSON.parse(localStorage.getItem(key) as string);
            let accessFormArray: any[] = [];
            const queryString = window.location.search;
            const context = await microsoftTeams.app.getContext();
            const tenantId = context.user?.tenant?.id as string;
            const azureId = context.user?.id as string;
            const urlParams = new URLSearchParams(queryString);
            const filePermId = urlParams.get('permFileId') as string;
            const fileId = urlParams.get('fileId') as string;
            const region = urlParams.get('region') as string;
            const userAuthenticated = await checkUserAuth(region) as boolean;

            if (userAuthenticated) {

                const userSettings: any = await hubService.getUserSettings(tenantId, azureId).catch((error) => {
                    if (error) {
                        microsoftTeams.app.notifySuccess();
                        this.setState({
                            error: true,
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            error: false,
                            isLoading: false
                        })
                    }
                });

                if (userSettings) {
                    let canCreateHubshareLink = userSettings.canCreateHubshareLink.isEnabled
                    let canCreateHubshareLinkSettings = userSettings.canCreateHubshareLink.children;
                    let includeAccessFormSetting = canCreateHubshareLinkSettings.link_lead_form;
                    let canEditLinkExpiryDate = canCreateHubshareLinkSettings.can_edit_link_expiry_date;
                    let formTemplatesEnabled = userSettings.hasTemplates.isWidgetEnabled && userSettings.hasTemplates?.children?.has_form_template_dashboard;
                    let addLeadFormLinkDefault = userSettings.addLeadFormLinkDefault;
                    let defaultLinkExpiryDuration = userSettings.defaultLinkExpiryDuration;

                    this.setState({
                        canCreateHubshareLink,
                        includeAccessFormSetting,
                        canEditLinkExpiryDate,
                        addLeadFormLinkDefault,
                        defaultLinkExpiryDuration,
                        formTemplatesEnabled
                    });

                    if (includeAccessFormSetting === "mandatory") {
                        this.setState({
                            includeAccessFormChecked: true
                        });
                    }

                    if (formTemplatesEnabled) {
                        const leadFormList = await hubService.getLeadFormList(tenantId, azureId) as any[];
                        leadFormList.map((form) => {
                            accessFormArray.push(form.name)
                            if (form.isDefault) {
                                this.setState({
                                    selectedLeadFormId: form.id,
                                    selectedLeadFormName: form.name
                                });
                            }
                        });

                        /* Extracting the name of the lead form in a seperate array because the component only allows to have one
                        element instead of the whole object like the Bigtincan RDS Select component */

                        this.setState({
                            leadFormList,
                            accessFormArray
                        });
                    }

                    if (addLeadFormLinkDefault == true) {
                        this.setState({
                            includeAccessFormChecked: true
                        });
                    }

                    if (Number(defaultLinkExpiryDuration) > 0) {
                        this.setState({
                            expireInDays: defaultLinkExpiryDuration,
                            expireLinkAfterChecked: true
                        })
                    }
                }

                const fileDetails = await hubService.getFileDetailsForShare(fileId, filePermId, tenantId, azureId);

                if (fileDetails) {
                    this.setState({
                        file: fileDetails,
                        isLoading: false
                    });
                }

                microsoftTeams.app.notifySuccess();
            } else {
                this.setState({
                    authError: true,
                    isLoading: false
                });

                microsoftTeams.app.notifySuccess();
            }

            if (window.innerWidth <= 767) {
                this.setState({
                    isMobile: true
                });
            }
        }).catch((error) => {
            this.setState({
                error: true,
                isLoading: false
            });
        });
    };

    async getExternalShareLink(fileId: string, filePermId: string) {

        this.setState({
            dialogOpen: true
        });

        const context = await microsoftTeams.app.getContext();
        const tenantId = context.user?.tenant?.id as string;
        const azureId = context.user?.id as string;

        const shareLinkData: { linkTitle: string, linkExpiry: number, includeAccessForm: boolean, leadFormId?: number } = {
            linkTitle: this.state.linkTitle,
            linkExpiry: this.state.expireLinkAfterChecked ? this.state.expireInDays : 0, // pass 0 if expire link checkbox is unchecked
            includeAccessForm: this.state.includeAccessFormChecked
        }

        if (this.state.formTemplatesEnabled && this.state.includeAccessFormChecked) {
            shareLinkData.leadFormId = this.state.selectedLeadFormId;
        }

        hubService.getExternalShareLink(fileId, filePermId, tenantId, azureId, shareLinkData).then((linkData: any) => {
            this.setState({
                externalLink: linkData
            });
        }).catch((error) => {
            this.setState({
                externalLink: "Unable to generate a share link, please try again."
            });
        })
    }

    async signInWithBigtincan() {

        this.setState({
            isLoading: true
        });

        const queryString = window.location.search;
        const context = await microsoftTeams.app.getContext();
        const tenantId = context.user?.tenant?.id as string;
        const azureId = context.user?.id as string;
        const urlParams = new URLSearchParams(queryString);
        const filePermId = urlParams.get('permFileId') as string;
        const fileId = urlParams.get('fileId') as string;
        const defaultRegion = urlParams.get('region') as string;

        let accessFormArray: any[] = [];

        authentication.authenticate({
            url: `${window.location.origin}/app/auth-start-bigtincan?region=${defaultRegion}`,
            height: 800,
            width: 600,
        }).then(async (result: any) => {
            let data = JSON.parse(result);
            if (data) {
                let authData = data.authData;
                let userInfo = data.userInfo;
                let idAccessToken = authData.idAccessToken
                localStorage.setItem("BTC", JSON.stringify(authData));
                const userSettings: any = await hubService.getUserSettings(tenantId, azureId).catch((error) => {
                    if (error) {
                        microsoftTeams.app.notifySuccess();
                        this.setState({
                            error: true,
                            isLoading: false
                        });
                    }
                });

                if (userSettings) {
                    let canCreateHubshareLink = userSettings.canCreateHubshareLink.isEnabled
                    let canCreateHubshareLinkSettings = userSettings.canCreateHubshareLink.children;
                    let includeAccessFormSetting = canCreateHubshareLinkSettings.link_lead_form;
                    let canEditLinkExpiryDate = canCreateHubshareLinkSettings.can_edit_link_expiry_date;
                    let addLeadFormLinkDefault = userSettings.addLeadFormLinkDefault;
                    let defaultLinkExpiryDuration = userSettings.defaultLinkExpiryDuration;
                    let formTemplatesEnabled = userSettings.hasTemplates.isWidgetEnabled && userSettings.hasTemplates?.children?.has_form_template_dashboard;

                    this.setState({
                        canCreateHubshareLink,
                        includeAccessFormSetting,
                        canEditLinkExpiryDate,
                        addLeadFormLinkDefault,
                        defaultLinkExpiryDuration,
                        formTemplatesEnabled
                    });

                    if (formTemplatesEnabled) {
                        const leadFormList = await hubService.getLeadFormList(tenantId, azureId) as any[];
                        leadFormList.map((form) => {
                            accessFormArray.push(form.name)
                            if (form.isDefault) {
                                this.setState({
                                    selectedLeadFormId: form.id,
                                    selectedLeadFormName: form.name
                                });
                            }
                        });

                        /* Extracting the name of the lead form in a seperate array because the component only allows to have one
                        element instead of the whole object like the Bigtincan RDS Select component */

                        this.setState({
                            leadFormList,
                            accessFormArray
                        });
                    }

                    if (addLeadFormLinkDefault === true) {
                        this.setState({
                            includeAccessFormChecked: true
                        });
                    }

                    if (Number(defaultLinkExpiryDuration) > 0) {
                        this.setState({
                            expireInDays: defaultLinkExpiryDuration,
                            expireLinkAfterChecked: true
                        });
                    }
                }

                const fileDetails = await hubService.getFileDetailsForShare(fileId, filePermId, tenantId, azureId);

                if (fileDetails) {
                    this.setState({
                        file: fileDetails,
                        isLoading: false,
                        error: false,
                        authError: false
                    });
                }

                microsoftTeams.app.notifySuccess();
            } else {
                this.setState({
                    displayAuthError: true,
                    isLoading: false,
                    authError: true
                }, () => this.hideAlert());
            }
        }).catch((reason) => {
            this.setState({
                displayAuthError: true,
                isLoading: false,
                authError: true
            }, () => this.hideAlert());
        });
    }

    async hideAlert() {
        setTimeout(() => {
            this.setState({
                displayAuthError: false,
            });
        }, 3000);
    }

    handleInteraction = (event: any) => {
        if (event.target === "signInWithBigtincan") {
            this.signInWithBigtincan();
        }
    }

    render() {
        const copyButtonClicked = (event: any) => {
            copy(this.state.externalLink);

            this.setState({
                displayAlert: true
            });

            setTimeout(() => {
                this.setState({
                    displayAlert: false
                });
            }, 2000);
        };

        const errorCommFields: ICommunicationFields = {
            title: `Something went wrong.`,
            desc: `Preview unavailable. Please try again.`,
        };

        const authErrorCommFields: ICommunicationFields = {
            title: `Looks like you are not signed-in.`,
            desc: ` Please sign-in and try again.`,
            actions: {
                primary: {
                    label: "Sign In with Bigtincan",
                    target: "signInWithBigtincan"
                },
            }
        };

        const onDateSelected = (event: any, value: any) => {
            const dateSelected: any = new Date(value.value);
            const currentDate: any = new Date();
            const diffInMs = Math.abs(currentDate - dateSelected);
            const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
            this.setState({
                expireInDays: diffInDays
            });
        }

        const onLinkTitleChange = (event: any) => {
            this.setState({
                linkTitle: event.target.value
            });
        }

        const onIncludeAccessFormCheckboxClicked = (event: any) => {
            this.setState({
                includeAccessFormChecked: !this.state.includeAccessFormChecked
            });
        }

        const onExpireLinkCheckboxClicked = (event: any) => {
            this.setState({
                expireLinkAfterChecked: !this.state.expireLinkAfterChecked
            });
        }

        const handleLeadFormSelect = (e: any, value: any) => {
            const selectedLeadForm = value.value;
            this.state.leadFormList.forEach((form) => {
                if (form.name == selectedLeadForm) {
                    let selectedLeadFormId = form.id;
                    this.setState({
                        selectedLeadFormId,
                        selectedLeadFormName: selectedLeadForm
                    });
                }
            });
        }

        let coverArt = this.state.file.cover_art?.url;
        let fileName = this.state.file.description;
        let createdAt = this.state.file.created_at;
        return (
            < div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh"
            }
            }>
                {
                    this.state.error &&
                    <div style={{ width: "100vh", height: "100vh", alignSelf: "center" }}>
                        <Communication
                            option={CommunicationOptions.Empty}
                            fields={errorCommFields}
                            onInteraction={this.handleInteraction}
                        />
                    </div>
                }
                {
                    this.state.authError &&
                    <>
                        <div style={{ width: "100vh", height: "auto", alignSelf: "center", overflow: "hidden" }}>
                            <Communication
                                option={CommunicationOptions.Empty}
                                fields={authErrorCommFields}
                                onInteraction={this.handleInteraction}
                            />
                            {
                                this.state.displayAuthError &&
                                <div>
                                    <AlertError />
                                </div>
                            }
                        </div>
                    </>
                }
                {
                    this.state.isLoading && !this.state.error && !this.state.authError &&
                    <div style={{
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        overflow: "hidden"
                    }}>
                        <Loader />
                    </div>
                }
                {
                    this.state.isMobile && !this.state.isLoading && !this.state.error && !this.state.authError &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        overflow: "hidden"
                    }}>
                        <Card style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <img src={coverArt} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                            </div>
                            <div style={{
                                padding: "30px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start"
                            }}>
                                <p style={{ fontSize: "20px", fontWeight: "bolder", margin: "0", paddingBottom: "10px" }}>{fileName}</p>
                                <div style={{ paddingTop: "10px" }}>
                                    <Form>
                                        <FormInput
                                            style={{ paddingTop: "10px" }}
                                            label="Link Title"
                                            name="linkTitle"
                                            value={this.state.linkTitle}
                                            required
                                            fluid
                                            color="black"
                                            onChange={onLinkTitleChange}
                                        />
                                        <FormCheckbox
                                            label="Expire link"
                                            checked={this.state.expireLinkAfterChecked}
                                            onChange={onExpireLinkCheckboxClicked}
                                            disabled={!this.state.canEditLinkExpiryDate}
                                        />
                                        {this.state.expireLinkAfterChecked &&
                                            (
                                                <div style={{ border: "1px", borderColor: "black" }}>
                                                    <FormDatepicker
                                                        minDate={new Date()}
                                                        disabled={!this.state.canEditLinkExpiryDate}
                                                        defaultSelectedDate={new Date(new Date().setDate(new Date().getDate() + Number(this.state.defaultLinkExpiryDuration)))}
                                                        onDateChange={(e, value) => { onDateSelected(e, value) }}
                                                    />
                                                </div>
                                            )
                                        }
                                        {this.state.includeAccessFormSetting === "optional" || this.state.includeAccessFormSetting === "mandatory" ? (
                                            <FormCheckbox
                                                label="Include access form"
                                                checked={this.state.includeAccessFormSetting === "mandatory" || this.state.includeAccessFormChecked}
                                                onChange={onIncludeAccessFormCheckboxClicked}
                                                disabled={this.state.includeAccessFormSetting === "mandatory" ? true : false}
                                            />
                                        ) : null}
                                        {this.state.formTemplatesEnabled && this.state.includeAccessFormChecked &&
                                            <FormDropdown
                                                items={this.state.accessFormArray}
                                                placeholder="Select access form"
                                                checkable
                                                value={this.state.selectedLeadFormName}
                                                onChange={(e, value) => handleLeadFormSelect(e, value)}
                                            />
                                        }
                                        <Dialog
                                            backdrop={true}
                                            content={
                                                <div style={{
                                                    overflowX: 'auto',
                                                    wordWrap: "break-word",
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                    {this.state.externalLink ? (
                                                        <>
                                                            <label>{this.state.externalLink}</label>
                                                            {this.state.displayAlert &&
                                                                <Alert
                                                                    style={{ marginTop: "20px" }}
                                                                    success
                                                                    content="Shareable link copied to Clipboard"
                                                                />
                                                            }
                                                        </>
                                                    )
                                                        : (<Loader />)
                                                    }
                                                </div>
                                            }
                                            open={this.state.dialogOpen}
                                            trigger={
                                                <FormButton
                                                    content="Generate link"
                                                    onClick={() => { this.getExternalShareLink(this.state.file.id, this.state.file.file_perm_id) }}
                                                />
                                            }
                                            headerAction={{
                                                icon: <CloseIcon />,
                                                title: 'Close',
                                                onClick: () => {
                                                    this.setState({
                                                        dialogOpen: false
                                                    })
                                                },
                                            }}
                                            header="Share link"
                                            confirmButton="Copy"
                                            onConfirm={(e: any) => copyButtonClicked(e)}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </Card>
                    </div>
                }
                {
                    !this.state.isMobile && !this.state.isLoading && !this.state.error && !this.state.authError &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "30px",
                        overflow: "hidden"
                    }}>
                        <Card style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "700px",
                            maxWidth: "700px",
                            padding: "20px",
                        }}>
                            <div style={{
                                width: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <img src={coverArt} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                            </div>
                            <div style={{
                                padding: "30px",
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start"
                            }}>
                                <p style={{ fontSize: "20px", fontWeight: "bolder", margin: "0", paddingBottom: "10px" }}>{fileName}</p>
                                <div style={{ paddingTop: "10px" }}>
                                    <Form>
                                        <FormInput
                                            style={{ paddingTop: "10px" }}
                                            label="Link Title"
                                            name="linkTitle"
                                            value={this.state.linkTitle}
                                            required
                                            fluid
                                            color="black"
                                            onChange={onLinkTitleChange}
                                        />
                                        <FormCheckbox
                                            label="Expire link"
                                            checked={this.state.expireLinkAfterChecked}
                                            onChange={onExpireLinkCheckboxClicked}
                                            disabled={!this.state.canEditLinkExpiryDate}
                                        />
                                        {this.state.expireLinkAfterChecked &&
                                            (
                                                <div style={{ border: "1px", borderColor: "black" }}>
                                                    <FormDatepicker
                                                        minDate={new Date()}
                                                        disabled={!this.state.canEditLinkExpiryDate}
                                                        defaultSelectedDate={new Date(new Date().setDate(new Date().getDate() + Number(this.state.defaultLinkExpiryDuration)))}
                                                        onDateChange={(e, value) => { onDateSelected(e, value) }}
                                                    />
                                                </div>
                                            )
                                        }
                                        {this.state.includeAccessFormSetting === "optional" || this.state.includeAccessFormSetting === "mandatory" ? (
                                            <FormCheckbox
                                                label="Include access form"
                                                checked={this.state.includeAccessFormSetting === "mandatory" || this.state.includeAccessFormChecked}
                                                onChange={onIncludeAccessFormCheckboxClicked}
                                                disabled={this.state.includeAccessFormSetting === "mandatory" ? true : false}
                                            />
                                        ) : null}
                                        <div >
                                            {this.state.formTemplatesEnabled && this.state.includeAccessFormChecked &&
                                                <FormDropdown
                                                    items={this.state.accessFormArray}
                                                    placeholder="Select access form"
                                                    checkable
                                                    value={this.state.selectedLeadFormName}
                                                    onChange={(e, value) => handleLeadFormSelect(e, value)}
                                                />
                                            }
                                        </div>
                                        <Dialog
                                            backdrop={true}
                                            content={
                                                <div style={{
                                                    overflowX: 'auto',
                                                    wordWrap: "break-word",
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                    {this.state.externalLink ? (
                                                        <>
                                                            <label>{this.state.externalLink}</label>
                                                            {this.state.displayAlert &&
                                                                <Alert
                                                                    style={{ marginTop: "20px" }}
                                                                    success
                                                                    content="Shareable link copied to Clipboard"
                                                                />
                                                            }
                                                        </>
                                                    )
                                                        : (<Loader />)
                                                    }
                                                </div>
                                            }
                                            open={this.state.dialogOpen}
                                            trigger={
                                                <FormButton
                                                    content="Generate link"
                                                    onClick={() => { this.getExternalShareLink(this.state.file.id, this.state.file.file_perm_id) }}
                                                />
                                            }
                                            headerAction={{
                                                icon: <CloseIcon />,
                                                title: 'Close',
                                                onClick: () => {
                                                    this.setState({
                                                        dialogOpen: false
                                                    })
                                                },
                                            }}
                                            header="Share link"
                                            confirmButton="Copy"
                                            onConfirm={(e) => copyButtonClicked(e)}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </Card >
                    </div >
                }
            </div >
        )
    }
}

export default Share;
