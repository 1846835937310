/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React, { useState, useEffect } from 'react';

const moment = require("moment");

const AdminAuthSuccess = () => {

    const [userInfo, setUserInfo] = useState({} as any);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const adminAuthData = JSON.parse(decodeURIComponent(urlParams.get('admin_auth_data') as string));
        const adminUserInfo = JSON.parse(urlParams.get('admin_user_info') as string);
        // const adminError = JSON.parse(urlParams.get('error') as string);

        if (adminAuthData && adminUserInfo) {

            let userAuthData = {
                btcid_access_token: adminAuthData.btcid_access_token,
                btcid_access_token_expiry: moment().add(adminAuthData.btcid_access_token_expiry, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
                btcid_refresh_token: adminAuthData.btcid_refresh_token,
                btch_access_token: adminAuthData.btch_access_token,
                btch_access_token_expiry: moment().add(adminAuthData.btch_access_token_expiry, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
                btch_refresh_token: adminAuthData.btch_refresh_token || null,
            }

            let userInfo = {
                btcUserId: adminUserInfo.id,
                firstName: adminUserInfo.first_name,
                lastName: adminUserInfo.last_name,
                btcUserEmail: adminUserInfo.email,
            }

            setUserInfo(userInfo);

            localStorage.setItem("BTC_ADMIN", JSON.stringify(userAuthData));
            localStorage.setItem("BTC_ADMIN_USER", JSON.stringify(adminUserInfo));

            window.location.href = '/admin/';
        }
    }, []);

    return null;
};

export default AdminAuthSuccess;