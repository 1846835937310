import * as React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const Loader = () => {
    const classes = cx(styles.Loader, styles.content);
    return (
        <div className={classes}>
            <svg className={styles.orange} viewBox="0 0 100 100">
                <circle
                    cx="50%" cy="50%" r="51"
                    opacity="0.65"
                />
            </svg>
            <svg className={styles.midGrey} viewBox="0 0 100 100">
                <circle
                    cx="50%" cy="50%" r="48"
                    opacity="0.65"
                />
            </svg>
            <svg className={styles.smallGrey} viewBox="0 0 100 100">
                <circle
                    cx="50%" cy="50%" r="51"
                    opacity="0.65"
                />
            </svg>
        </div>
    );
};
export default Loader;