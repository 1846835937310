/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React, { Component } from 'react';
import { checkAdminUserAuth } from "../../adminAuth";
import AdminLogin from "../../components/AdminLogin";
import Navigation from "../../components/Navigation";
import Topbar from "../../components/Topbar";
import Installations from "../Installations";
import Tenants from "../Tenants";
import UserAuth from "../UserAuth";

interface IAdminState {
    activeView: string,
    userAuthenticated: boolean,
    isLoading: boolean,
    firstName: string,
    lastName: string,
    error: string,
    displayAuthError: boolean
}

class Admin extends Component<{}, IAdminState> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeView: "Tenants",
            userAuthenticated: false,
            isLoading: false,
            firstName: "",
            lastName: "",
            error: "",
            displayAuthError: false
        };
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const adminError = urlParams.get('error') as string;

        if (adminError) {
            setTimeout(() => {
                this.setState({
                    error: adminError,
                    displayAuthError: true
                });

                setTimeout(() => {
                    this.setState({
                        error: "",
                        displayAuthError: false
                    });
                }, 3000);
            }, 3000);
        }

        this.checkAuthentication();

        const userInfo = JSON.parse(localStorage.getItem("BTC_ADMIN_USER") as string);
        if (userInfo) {
            this.setState({
                firstName: userInfo.firstName,
                lastName: userInfo.lastName
            });
        }
    }

    checkAuthentication = async () => {
        const isAuthenticated = await checkAdminUserAuth() as boolean;
        this.setState({
            userAuthenticated: isAuthenticated,
            isLoading: false
        });
    };

    setActiveView = (viewName: string) => {
        this.setState({ activeView: viewName });
    };

    renderView = () => {
        const { activeView } = this.state;

        switch (activeView) {
            // case 'Dashboard':
            //     return <p>Dashboard View</p>;
            case 'Installation logs':
                return <Installations />;
            case 'User Auth logs':
                return <UserAuth />;
            case 'Tenants':
                return <Tenants />;
            default:
                return <Tenants />;
        }
    };

    render() {
        const { firstName, lastName, userAuthenticated, isLoading } = this.state;
        return (
            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                overflow: "auto"
            }}>
                <Topbar
                    firstName={firstName}
                    lastName={lastName}
                    userAuthenticated={userAuthenticated}
                />
                {userAuthenticated && !isLoading &&
                    <div style={{
                        display: "flex",
                        flex: 1,
                        overflow: "auto"
                    }}>
                        <Navigation onNavClick={this.setActiveView} activeView={this.state.activeView} />
                        <div style={{
                            flex: 1,
                            overflow: "auto"
                        }}>
                            {this.renderView()}
                        </div>
                    </div>
                }
                {!userAuthenticated && !isLoading &&
                    <AdminLogin error={this.state.error} displayAuthError={this.state.displayAuthError} />
                }
            </div>
        );
    }
}

export default Admin;
