/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import { Button, Dialog, TextField, Dropdown, useToastStore, ToastContainer } from "@bigtincan/react-design-system";
import React, { useEffect, useState } from "react";
import SettingTooltip from "../Tooltip";

interface ITenantSettingsDialogState {
    selectedRow: any,
    selectedRowSettings: any,
    text: string,
    checked: boolean
}

interface ITenantSettingsDialogProps {
    isOpen: boolean,
    settings: any,
    onSave: Function,
    onCancel: Function
}

const TenantSettingsDialog: React.FunctionComponent<ITenantSettingsDialogProps> = ({ isOpen, settings, onSave, onCancel }) => {

    const [updatedSettings, setUpdatedSettings] = useState({ ...settings });
    const addToast = useToastStore((store) => store.addToast);

    useEffect(() => {
        setUpdatedSettings({ ...settings });
    }, [settings]);

    const allowedRegions = ["ORG", "INFO", "US", "UK", "AU"];
    const allowedOptions = ["Yes", "No"]; // Options available for GENIE_AI_ENABLED and PREVIEW_ENABLED setting

    const handleSave = () => {
        onSave(updatedSettings);
        addToast({
            message: 'Tenant settings saved.',
            type: 'positive'
        });
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleSettingChange = (value: string, settingKey: string) => {
        const newSettings = { ...updatedSettings, [settingKey]: value };
        setUpdatedSettings(newSettings);
    };

    const handleSelectionChange = (settingKey: string, optionSelected: string) => {
        let settingValue = optionSelected;

        if (optionSelected === "Yes") {
            settingValue = "1";
        } else if (optionSelected === "No") {
            settingValue = "0";
        }

        const newSettings = { ...updatedSettings, [settingKey]: settingValue };
        setUpdatedSettings(newSettings);
    };

    const settingRowStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: '100%',
        marginBottom: '16px'
    };

    return (
        <Dialog isOpen={isOpen} onClickOutside={handleCancel} onXClick={handleCancel}>
            <Dialog.Header>Settings</Dialog.Header>
            <Dialog.Body>
                <ToastContainer />
                <div style={{ ...settingRowStyle, justifyContent: "flex-start" }}>
                    <p style={{ width: '40%', paddingRight: "60px" }}>BTC REGION <SettingTooltip settingKey="BTC_REGION" />
                    </p>
                    <div style={{ width: '60%' }}>
                        <Dropdown
                            options={allowedRegions}
                            placeholder='Choose region'
                            selection={updatedSettings?.BTC_REGION}
                            onSelection={(e: any) => handleSelectionChange("BTC_REGION", e)}
                            variant='solid'
                            width="265px"
                        />
                    </div>
                </div>
                <div style={{ ...settingRowStyle, justifyContent: "flex-start" }}>
                    <label htmlFor="APP_ID" style={{ width: '40%' }}>APP ID <SettingTooltip settingKey="APP_ID" /></label>
                    <TextField
                        onChange={(value: string) => handleSettingChange(value, "APP_ID")}
                        value={updatedSettings?.APP_ID || ""}
                        label=""
                        id="APP_ID"
                        style={{ width: '60%' }}
                    />
                </div>
                <div style={{ ...settingRowStyle, justifyContent: "flex-start" }}>
                    <label htmlFor="SSO_ID" style={{ width: '40%' }}>SSO ID <SettingTooltip settingKey="SSO_ID" /></label>
                    <TextField
                        onChange={(value: string) => handleSettingChange(value, "SSO_ID")}
                        value={updatedSettings?.SSO_ID || ""}
                        label=""
                        id="SSO_ID"
                        style={{ width: '60%' }}
                    />
                </div>
                <div style={{ ...settingRowStyle, justifyContent: "flex-start" }}>
                    <label htmlFor="CUSTOMER_CODE" style={{ width: '40%' }}>CUSTOMER CODE <SettingTooltip settingKey="CUSTOMER_CODE" /></label>
                    <TextField
                        onChange={(value: string) => handleSettingChange(value, "CUSTOMER_CODE")}
                        value={updatedSettings?.CUSTOMER_CODE || ""}
                        label=""
                        id="CUSTOMER_CODE"
                        style={{ width: '60%' }}
                    />
                </div>
                <div style={{ ...settingRowStyle, justifyContent: "flex-start" }}>
                    <label htmlFor="DEBUG_OPTIONS" style={{ width: '40%' }}>DEBUG OPTIONS <SettingTooltip settingKey="DEBUG_OPTIONS" /></label>
                    <TextField
                        onChange={(value: string) => handleSettingChange(value, "DEBUG_OPTIONS")}
                        value={updatedSettings?.DEBUG_OPTIONS || ""}
                        label=""
                        id="DEBUG_OPTIONS"
                        style={{ width: '60%' }}
                    />
                </div>
                <div style={{ ...settingRowStyle, justifyContent: "flex-start" }}>
                    <p style={{ width: '40%' }}>GENIE AI <SettingTooltip settingKey="GENIE_AI_ENABLED" /></p>
                    <div style={{ width: '60%' }}>
                        <Dropdown
                            options={allowedOptions}
                            placeholder=''
                            selection={updatedSettings?.GENIE_AI_ENABLED === "1" ? "Yes" : updatedSettings?.GENIE_AI_ENABLED === "0" ? "No" : ""}
                            onSelection={(e: any) => handleSelectionChange("GENIE_AI_ENABLED", e)}
                            variant='solid'
                            width="265px"
                        />
                    </div>
                </div>
                <div style={{ ...settingRowStyle, justifyContent: "flex-start" }}>
                    <p style={{ width: '40%' }}>PREVIEW ENABLED <SettingTooltip settingKey="PREVIEW_ENABLED" /></p>
                    <div style={{ width: '60%' }}>
                        <Dropdown
                            options={allowedOptions}
                            placeholder=''
                            selection={updatedSettings?.PREVIEW_ENABLED === "1" ? "Yes" : updatedSettings?.PREVIEW_ENABLED === "0" ? "No" : ""}
                            onSelection={(e: any) => handleSelectionChange("PREVIEW_ENABLED", e)}
                            variant='solid'
                            width="265px"
                        />
                    </div>
                </div>
            </Dialog.Body>
            <Dialog.Footer>
                <Dialog.FooterButtonWrapper >
                    <Button color='neutral' onClick={handleCancel}>Cancel</Button>
                    <Button color='tint' onClick={handleSave}>Save</Button>
                </Dialog.FooterButtonWrapper>
            </Dialog.Footer>
        </Dialog>
    );
}

export default TenantSettingsDialog;