/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import * as microsoftTeams from "@microsoft/teams-js";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Link } from "@fluentui/react-components";

interface ConfigureMeetingState {
    defaultRegion: string;
    appClientId: string;
    userAuthenticated: boolean;
    isLoading: boolean;
    firstName: string;
    lastName: string;
    userEmail: string;
    region: string;
    frameContext: string;
    files: any;
    fileResult: any;
    searchKeyword: string;
    searchOpen: boolean;
    selectedFiles: any;
    displayAuthError: boolean;
}

export default class Configure extends React.Component<{}, ConfigureMeetingState> {

    async componentDidMount() {
        microsoftTeams.app.initialize().then(async () => {
            microsoftTeams.app.notifySuccess();
            microsoftTeams.pages.config.registerOnSaveHandler((saveEvent: microsoftTeams.settings.SaveEvent) => {

                microsoftTeams.pages.config.setConfig({
                    suggestedDisplayName: "Bigtincan",
                    entityId: "BigtincanMeetingTab",
                    contentUrl: `${window.location.origin}/app/meeting/home`
                });

                saveEvent.notifySuccess();
            });
            microsoftTeams.pages.config.setValidityState(true);
        });
    }

    contactUs(event: any) {
        event?.preventDefault();
        let userResponse = window.confirm("This link will take you to an external site. Do you want to proceed?");
        if (userResponse === true) {
            window.open("https://www.bigtincan.com/contact/");
        }
    }

    render() {
        return (
            <div style={{
                display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column", width: "100%", position: "fixed"
            }}>
                <div style={{
                    textAlign: "center", marginBottom: "10px", fontSize: "30px", lineHeight: "30px", fontFamily: "Open Sans, sans-serif"
                }}>Get started with Bigtincan</div>
                <div style={{ justifyContent: "flex-start", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <Badge appearance="outline" size="extra-large">1</Badge>
                        <p style={{ marginLeft: "10px" }}>Search and add content to the meeting</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <Badge appearance="outline" size="extra-large">2</Badge>
                        <p style={{ marginLeft: "10px" }}>Present content during the meeting</p>
                    </div>
                </div>
                <p style={{ textAlign: "center", width: "80%" }}>You need an active Bigtincan account to use this app. Don't have a Bigtincan account ? <Link onClick={(e) => this.contactUs(e)} > Contact Us</Link></p>
            </div >
        )
    };
};