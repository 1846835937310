/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React, { Component } from "react";
import Loader from "../../components/Loader";
import * as microsoftTeams from "@microsoft/teams-js";
import {
    Communication,
    CommunicationOptions,
    ICommunicationFields,
} from "@fluentui/react-teams";
import { authentication } from "@microsoft/teams-js";
import { hubService } from "../../hubService";
import AlertError from "../../components/Alert/Alert";
import { checkUserAuth } from "../../auth";
import { Login } from "../../components/Login";

const moment = require("moment");

class Home extends Component<{},
    {
        firstName: string,
        lastName: string,
        region: string,
        userEmail: string,
        defaultRegion: string,
        enabledRegions: string | null,
        regionNames: any[],
        regionCodes: any[],
        isLoading: boolean,
        userAuthenticated: boolean,
        appClientId: string,
        displayAuthError: boolean,
    }>
{
    constructor(props: any) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            region: "",
            userEmail: "",
            defaultRegion: "",
            enabledRegions: null,
            regionNames: [],
            regionCodes: [],
            isLoading: true,
            userAuthenticated: false,
            appClientId: "",
            displayAuthError: false,
        };
        // this.signInWithMicrosoft = this.signInWithMicrosoft.bind(this);
    }

    async componentDidMount() {
        microsoftTeams.app.initialize().then(async () => {

            const userAuthenticated = await checkUserAuth() as boolean;

            if (userAuthenticated) {

                let userInfo = JSON.parse(localStorage.getItem("userInfo") as string);

                if (userInfo) {
                    this.setState({
                        firstName: userInfo.first_name,
                        lastName: userInfo.last_name,
                        userEmail: userInfo.user_email,
                        userAuthenticated: userAuthenticated,
                        isLoading: false
                    });
                }
                microsoftTeams.app.notifySuccess();

            } else {

                this.setState({
                    userAuthenticated: false,
                    isLoading: false
                });
                microsoftTeams.app.notifySuccess();

            }
        });
    };

    learnMore() {
        let userResponse = window.confirm("This link will take you to an external site. Do you want to proceed?");
        if (userResponse) {
            window.open("https://help.bigtincan.com/hc/en-us/articles/20674333286413-Bigtincan-App-for-Microsoft-Teams");
        }
    }

    contactUs(event: any) {
        event?.preventDefault();
        let userResponse = window.confirm("This link will take you to an external site. Do you want to proceed?");
        if (userResponse === true) {
            window.open("https://www.bigtincan.com/contact/");
        }
    }

    async hideAlert() {
        setTimeout(() => {
            this.setState({
                displayAuthError: false,
            });
        }, 3000);
    }

    render() {

        const helloCommFields: ICommunicationFields = {
            title: `Hi ${this.state.firstName} ${this.state.lastName}!`,
            desc: `You are logged in as ${this.state.userEmail}. You can now search, share, preview Bigtincan content and receive notifications within Microsoft Teams.`,
            actions: {
                primary: {
                    label: "Sign Out",
                    target: "signOut"
                }
            }
        };

        const signOutUser = async () => {
            this.setState({
                userAuthenticated: false,
                isLoading: true
            });
            let context = await microsoftTeams.app.getContext();
            let azureAdObjectId = context.user?.id as string;
            let tenantId = context.user?.tenant?.id as string;

            await hubService.signOutUser(tenantId, azureAdObjectId).then(() => {
                localStorage.clear();
                this.setState({
                    isLoading: false,
                    firstName: "",
                    lastName: "",
                    userEmail: "",
                    userAuthenticated: false,
                    region: ""
                })
            });
        };

        const handleAction = (event: any) => {
            if (event.target === "signOut") {
                signOutUser();
            } else if (event.target === "learnMore") {
                this.learnMore();
            }
        };

        if (this.state.userAuthenticated == true) {
            return (
                <div>
                    {this.state.isLoading ?
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed"
                        }}>
                            <Loader />
                        </div>
                        :
                        <div style={{
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            position: "fixed"
                        }}>
                            <Communication
                                option={CommunicationOptions.Hello}
                                fields={helloCommFields}
                                onInteraction={handleAction}
                            />
                        </div>
                    }
                </div >
            )
        } else {
            return (
                <div>
                    {this.state.isLoading ?
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed"
                        }}>
                            <Loader />
                        </div>
                        :
                        <Login />
                    }
                </div >
            )
        }
    }
}

export default Home;
