/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import * as msal from "@azure/msal-browser";

const moment = require("moment");

class BigtincanClosePopup extends React.Component {

    componentDidMount() {
        microsoftTeams.app.initialize().then(() => {
            try {

                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const accessToken = urlParams.get('access_token');
                const refreshToken = urlParams.get('refresh_token');
                const expiresIn = urlParams.get("expires_in");
                const idAccessToken = urlParams.get("id_access_token");
                const idRefreshToken = urlParams.get("id_refresh_token");
                const idExpiresIn = urlParams.get("id_expires_in");
                const userInfo = JSON.parse(urlParams.get("user_info") as string);

                if (accessToken) {
                    let key = JSON.stringify({
                        authData: {
                            accessToken,
                            refreshToken,
                            expiresIn: moment().add(expiresIn, "seconds").format('YYYY-MM-DD HH:mm:ss'),
                            idRefreshToken,
                            idAccessToken,
                            idExpiresIn: moment().add(idExpiresIn, "seconds").format('YYYY-MM-DD HH:mm:ss'),
                        },
                        userInfo
                    });
                    microsoftTeams.authentication.notifySuccess(key);
                } else {
                    microsoftTeams.authentication.notifyFailure(`Access token not found in URL fragment`);
                }
            } catch (error: any) {
                microsoftTeams.authentication.notifyFailure(error.toString());
            }
        });
    }


    render() {
        return (
            <div>
                <h1>Consent flow complete.</h1>
            </div>
        );
    }
}

export default BigtincanClosePopup;