/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import { EXPERIMENTAL_Pagination, TextField } from "@bigtincan/react-design-system";
import React, { useState, useEffect } from "react";
import TenantsTableComponent from "../../components/TenantsTableComponent";
import { hubService } from "../../hubService";

const Tenants = () => {
    const [tenantSearchTerm, setTenantSearchTerm] = useState("");
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        hubService.getTenantData(tenantSearchTerm, limit, offset).then((data: any) => {
            setRows(data.rows);
            setTotalCount(data.totalCount)
            setIsLoading(false);
        });
    }, [tenantSearchTerm, currentPage])


    const handlePageChange = (newPage: any) => {
        let newOffset = limit * (currentPage - 1);

        setOffset(newOffset);
        setCurrentPage(newPage);
    };

    const handleTenantSearchChange = (query: string) => {
        setTenantSearchTerm(query);
    };

    return (
        <div style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            overflow: "auto"
        }}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "20px",
                paddingRight: "20px"
            }}>
                <h1>Tenants</h1>
                <div style={{ paddingTop: "20px" }}>
                    <TextField
                        placeholder='Search Tenant ID'
                        variant='solid'
                        value={tenantSearchTerm}
                        onChange={(e) => handleTenantSearchChange(e)}
                        style={{ marginRight: '16px', width: '360px' }}
                    />
                </div>
            </div>
            <div style={{ paddingRight: "40px", paddingLeft: "20px", overflow: "auto" }}>
                <TenantsTableComponent rows={rows} isLoading={isLoading} />
            </div>
            {totalCount > 1 && (
                <EXPERIMENTAL_Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    selected={itemsPerPage}
                    variant="outline"
                />
            )}
        </div>
    );
};

export default Tenants;

