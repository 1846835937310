/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import axios from "axios";
import { fetchEventSource } from '@microsoft/fetch-event-source';

export const hubService = {
    getExternalShareLink(fileId: string, filePermId: string, tenantId: string, aadId: string, shareLinkData: any) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;

        const linkTitle = shareLinkData.linkTitle;
        const expireInDays = shareLinkData.linkExpiry;
        const includeAccessForm = shareLinkData.includeAccessForm;
        const leadFormId = shareLinkData.leadFormId;

        return new Promise<any>((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/external-share-link`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    fileId,
                    filePermId,
                    tenantId,
                    azureId: aadId,
                    linkTitle,
                    expireInDays,
                    includeAccessForm,
                    leadFormId
                }
            }).then(async (response: any) => {
                if (response) {
                    resolve(response.data);
                }
                else {
                    console.error("Error in generating external share links")
                    reject("Error in generating external share links");
                }
            }).catch((error: any) => {
                console.error("Error in API call to generate external share links", { error });
                resolve('');
            })
        });
    },
    getFileDetailsForShare(fileId: string, filePermId: string, tenantId: string, aadId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise<any>((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/share-file-details`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    fileId,
                    filePermId,
                    tenantId,
                    azureId: aadId
                }
            }).then(async (searchResponse: any) => {
                if (searchResponse) {
                    resolve(searchResponse.data);
                } else {
                    console.error("Error in getting file details for Share")
                    reject("Error in getting file details for Share");
                }
            }).catch((error: any) => {
                console.error(`Error in getting file details for FILE ID : ${fileId}, FILE PERM ID : ${filePermId}`, { error });
                resolve('');
            })
        });
    },
    signOutUser(tenantId: string, azureAdObjectId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;

        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${window.location.origin}/signout`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    azureId: azureAdObjectId,
                    tenantId
                }
            }).then(async (response: any) => {
                if (response.status == "204") {
                    resolve(response.data);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    getUserInfo(idAccessToken: string, tenantId: string, azureId: string, region?: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/user/info`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    tenantId,
                    azureId,
                    region
                },
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    teamsTokenExchange(token: string, context: any, region: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${window.location.origin}/teams/token-exchange`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: {
                    context,
                    region
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    getSignInURLs(tenantId: string, azureAdObjectId: string, region: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/signin`,
                params: {
                    tenantId,
                    azureAdObjectId,
                    region
                },
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    getTenantSettings(tenantId: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/tenant/settings`,
                params: {
                    tenantId,
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    getLoggedInUser(tenantId: string, azureAdObjectId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/check-user-auth`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    tenantId,
                    azureId: azureAdObjectId,
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    async getPreviewLink(accessToken: string, tenantId: string, azureId: string, fileId: string, filePermId: string, urn?: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/file/preview`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    azureId,
                    tenantId,
                    fileId,
                    filePermId,
                    urn
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    getUserLoginSession(token: string, tenantId: string, azureAdObjectId: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/sync-user-session`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    tenantId,
                    azureId: azureAdObjectId,
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    refreshClientSideTokens(tenantId: string, azureId: string, idAccessToken: string, idRefreshToken: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${window.location.origin}/refresh-tokens`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                data: {
                    idRefreshToken,
                    azureId,
                    tenantId
                }
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getUserSettings(tenantId: string, azureId: string,) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/user/settings`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    azureId,
                    tenantId
                }
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getFileSearchResults(searchText: string, tenantId: string, aadId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;

        return new Promise<any>((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/files/search`,
                headers: {
                    'rhr': 1,
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    q: searchText,
                    keyword: searchText,
                    tenantId,
                    azureId: aadId
                }
            }).then(async (searchResponse: any) => {
                if (searchResponse && searchResponse.status == 200) {
                    resolve({ files: searchResponse.data.data });
                }
                else {
                    console.error("Error in searching for files")
                    reject("Error in searching for files");
                }
            }).catch((error: any) => {
                console.error("Error in API call to search for files", { error });
                resolve('');
            })
        });
    },
    getFileDetails(filePermIds: any, tenantId: string, aadId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise<any>((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/file-details`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    filePermIdArray: filePermIds,
                    tenantId,
                    azureId: aadId
                }
            }).then(async (searchResponse: any) => {
                if (searchResponse) {
                    resolve({ files: searchResponse.data });
                }
                else {
                    console.error("Error in searching for files")
                    reject("Error in searching for files");
                }
            }).catch((error: any) => {
                console.error("Error in API call to search for files", { error });
                resolve('');
            })
        });
    },
    saveMeetingRecord(chatId: string, azureId: string, tenantId: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${window.location.origin}/meetings/record`,
                data: {
                    chatId,
                    azureId,
                    tenantId
                }
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    updateContentInMeeting(tenantId: string, azureId: string, meetingId: string, filePermId: string, actionType: string, region: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${window.location.origin}/content/update`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                data: {
                    tenantId,
                    azureId,
                    meetingId,
                    filePermId,
                    actionType,
                    region
                }
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getContentAddedToMeeting(meetingId: string, azureId: string, tenantId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/meeting/content`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    tenantId,
                    azureId,
                    meetingId,
                }
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getInstallationData(tenantId?: string, tenantSearchQuery?: string, azureIdSearchQuery?: string, showDeleted?: boolean, limit?: number, offset?: number) {

        let storage = JSON.parse(localStorage.getItem("BTC_ADMIN") as string);
        let idAccessToken = storage.btcid_access_token;
        let params = { settings: 0, limit, offset } as {
            settings: number,
            tenantId: string,
            tenantIdSearchKeyword: string,
            azureIdSearchQuery: string,
            showDeleted: boolean,
            limit: number,
            offset: number
        };

        if (tenantId) {
            params.tenantId = tenantId
        }

        if (tenantSearchQuery) {
            params.tenantIdSearchKeyword = tenantSearchQuery
        }

        if (azureIdSearchQuery) {
            params.azureIdSearchQuery = azureIdSearchQuery
        }

        if (showDeleted == false) {
            params.showDeleted = false
        } else {
            params.showDeleted = true
        }

        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/api/v1/admin/installations`,
                params,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                }
            }).then(async (response) => {
                if (response) {
                    let responseData = response.data;
                    let rows: any = [];

                    responseData.forEach((element: any) => {

                        let id = element.id;
                        let tenantId = element.tenantId;
                        let azureId = element.azureId;
                        let deleted = element.deleted;
                        let createdAt = element.createdAt;
                        let updatedAt = element.updatedAt;

                        rows.push({
                            id,
                            tenantId,
                            azureId,
                            deleted,
                            createdAt,
                            updatedAt
                        });
                    });
                    resolve(rows);
                }
                else {
                    reject("error");
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getUserAuthData(tenantId?: string, tenantSearchQuery?: string, azureIdSearchQuery?: string, showDeleted?: boolean, limit?: number, offset?: number) {

        let storage = JSON.parse(localStorage.getItem("BTC_ADMIN") as string);
        let idAccessToken = storage.btcid_access_token;
        let params = { settings: 1, limit, offset } as {
            settings: number,
            tenantId: string,
            tenantSearchQuery: string,
            azureIdSearchQuery: string,
            showDeleted: boolean,
            limit: number,
            offset: number
        };

        if (tenantId) {
            params.tenantId = tenantId
        }

        if (showDeleted == false) {
            params.showDeleted = false
        } else {
            params.showDeleted = true
        }

        if (tenantSearchQuery) {
            params.tenantSearchQuery = tenantSearchQuery
        }

        if (azureIdSearchQuery) {
            params.azureIdSearchQuery = azureIdSearchQuery
        }

        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/api/v1/admin/users`,
                params,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                }
            }).then(async (response) => {
                if (response) {
                    let responseData = response.data.rows;
                    let totalCount = response.data.totalCount;
                    let rows: any = [];

                    responseData.forEach((element: any) => {

                        let id = element.id;
                        let tenantId = element.mst_tenant_id;
                        let azureId = element.mst_user_aad_id;
                        let userId = element.mst_user_id;
                        let btcRegion = element.btc_region;
                        let btcUserId = element.btc_user_id;
                        let btcUserEmail = element.btc_user_email;
                        let deleted = element.deleted;
                        let createdAt = element.created_at;

                        rows.push({
                            id,
                            tenantId,
                            azureId,
                            userId,
                            deleted,
                            btcRegion,
                            btcUserId,
                            btcUserEmail,
                            createdAt
                        });
                    });
                    resolve({ totalCount, rows });
                }
                else {
                    reject("error");
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getTenantData(tenantIdSearchQuery?: string, limit?: number, offset?: number) {
        let storage = JSON.parse(localStorage.getItem("BTC_ADMIN") as string);
        let idAccessToken = storage.btcid_access_token;
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/api/v1/admin/tenants`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    searchQuery: tenantIdSearchQuery,
                    limit,
                    offset
                }
            }).then(async (response) => {
                if (response) {

                    let responseData = response.data.rows;
                    let totalCount = response.data.totalCount;
                    let rows: any = [];

                    responseData.forEach((element: any) => {

                        let tenantId = element.tenantId;
                        let userCount = element.userCount;
                        let tenantSettings = element.tenantSettings;

                        rows.push({
                            tenantId,
                            userCount,
                            tenantSettings
                        });
                    });
                    resolve({ totalCount, rows });
                } else {
                    resolve({ totalCount: 0, rows: [] })
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getAdminSignInURL(region: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/admin/signin`,
                params: {
                    region
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    editTenantSettings(tenantId: string, settings: any) {
        let storage = JSON.parse(localStorage.getItem("BTC_ADMIN") as string);
        let idAccessToken = storage.btcid_access_token;
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${window.location.origin}/api/v1/admin/tenant/settings`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                data: {
                    tenantId,
                    settings
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    refreshAdminClientTokens(idAccessToken: string, idRefreshToken: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${window.location.origin}/refresh-admin-tokens`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                data: {
                    idRefreshToken,
                }
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getTenantSettingsForAdmin(tenantId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC_ADMIN") as string);
        let idAccessToken = storage.btcid_access_token;
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/api/v1/admin/tenant/settings/fetch`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    tenantId,
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    getLeadFormList(tenantId: string, azureId: string) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        let idAccessToken = storage.idAccessToken;
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${window.location.origin}/lead-form/list`,
                headers: {
                    'Authorization': `Bearer ${idAccessToken}`,
                },
                params: {
                    tenantId,
                    azureId
                }
            }).then(async (response: any) => {
                resolve(response.data)
            }).catch((error: any) => {
                reject(error);
            });
        });
    },
    async getGenieSearchResult(searchQuery: string, tenantId: string, azureId: string, streamResponse: (relevantDocs: any[], response: string) => void) {
        let storage = JSON.parse(localStorage.getItem("BTC") as string);
        const idAccessToken = storage.idAccessToken;
        const endpointURL = `${window.location.origin}/search/genie?tenantId=${tenantId}&azureId=${azureId}&searchQuery=${encodeURIComponent(searchQuery)}`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idAccessToken}`
        }

        return new Promise(async (resolve, reject) => {
            let docsArr: any[] = [];
            try {
                // Using this fetchEventSource rather than the EventSource constructor
                // because it allows us to pass in headers etc
                await fetchEventSource(endpointURL, {
                    method: 'GET',
                    headers: headers,
                    onmessage(event: any) {
                        if (event.data === '[DONE]') {
                            streamResponse(docsArr, "");
                        } else if (event.data.startsWith('{')) {
                            const message = JSON.parse(event.data);
                            if (message.type === 'content') {
                                streamResponse(docsArr, message);
                            } else if (message.type === 'vectorSearchResults') {
                                docsArr = message.documents
                            }
                        }
                    },
                    onclose() {
                        // setLoading(false);
                    },
                    onerror(err) {
                        // setLoading(false);
                        throw new Error(err); // rethrow to stop the operation
                    },
                    openWhenHidden: true,
                });
            } catch (error) {
                console.log(error);
            }
        });
    }
}