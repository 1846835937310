/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { hubService } from "../hubService";
import { UnifiedContentPlayer } from '@bigtincan/unified-content-player';
import { Communication, CommunicationOptions, ICommunicationFields } from '@fluentui/react-teams';
import { authentication } from "@microsoft/teams-js";
import { checkUserAuth } from '../auth';
import Loader from './Loader';
import AlertError from './Alert/Alert';

interface IFileViewerState {
    type: string,
    filePreviewLink: string,
    displayAuthError: boolean,
    isLoading: boolean
    error: boolean,
    authError: boolean,
    userAuthenticated: boolean
}

export default class FileViewer extends React.Component<{}, IFileViewerState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            type: "",
            filePreviewLink: "",
            error: false,
            authError: false,
            userAuthenticated: false,
            displayAuthError: false
        };
    }

    async componentDidMount() {
        microsoftTeams.app.initialize().then(async () => {

            this.setState({
                isLoading: true
            });

            const userAuthenticated = await checkUserAuth() as boolean;
            const queryString = window.location.search;
            const context = await microsoftTeams.app.getContext();
            const tenantId = context.user?.tenant?.id as string;
            const azureId = context.user?.id as string;
            const urlParams = new URLSearchParams(queryString);
            const filePermId = urlParams.get('permFileId') as string;
            const fileId = urlParams.get('fileId') as string;
            const urn = urlParams.get('urn') as string;

            let storage = JSON.parse(localStorage.getItem("BTC") as string);
            // let parsedStorage;

            // try {
            //     parsedStorage = JSON.parse(storage as string);
            // } catch (error) {
            //     console.error("Error parsing JSON ", error);
            //     parsedStorage = null;
            // }

            if (userAuthenticated) {
                let idAccessToken = storage?.idAccessToken;

                const { type, filePreviewLink }: any = await hubService.getPreviewLink(idAccessToken, tenantId, azureId, fileId, filePermId, urn).catch((error: any) => {
                    if (error) {
                        microsoftTeams.app.notifySuccess();
                        this.setState({
                            error: true,
                            isLoading: false
                        });
                    }
                });
                if (type && filePreviewLink) {
                    microsoftTeams.app.notifySuccess();
                    this.setState({
                        type,
                        filePreviewLink,
                        error: false,
                        authError: false,
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    authError: true,
                    isLoading: false
                });

                microsoftTeams.app.notifySuccess();
            }
        });


        // if (idAccessToken) {

        // } else {
        //     authentication.getAuthToken().then((result: string) => {
        //         let token = result;
        //         hubService.getUserLoginSession(token, tenantId, azureId).then(async (response: any) => {
        //             if (response) {
        //                 localStorage.setItem("BTC", JSON.stringify(response.authData));
        //                 let accessToken = response.authData.idAccessToken;
        //                 if (accessToken) {
        //                     const { type, filePreviewLink }: any = await hubService.getPreviewLink(idAccessToken, tenantId, azureId, fileId, filePermId).catch((error: any) => {
        //                         if (error) {
        //                             microsoftTeams.app.notifySuccess();
        //                             this.setState({
        //                                 error: true
        //                             });
        //                         }
        //                     });
        //                     if (type && filePreviewLink) {
        //                         microsoftTeams.app.notifySuccess();
        //                         this.setState({
        //                             type,
        //                             filePreviewLink,
        //                             error: false,
        //                             authError: false
        //                         });
        //                     }
        //                 } else {
        //                     this.setState({
        //                         error: false,
        //                         authError: true
        //                     });
        //                     microsoftTeams.app.notifySuccess();
        //                 }
        //             } else {
        //                 this.setState({
        //                     error: false,
        //                     authError: true
        //                 });
        //                 microsoftTeams.app.notifySuccess();
        //             }
        //         }).catch((error) => {
        //             this.setState({
        //                 error: false,
        //                 authError: true
        //             });
        //             microsoftTeams.app.notifySuccess();
        //         });
        //     }).catch((error: any) => {
        //         this.setState({
        //             error: false,
        //             authError: true
        //         });
        //         microsoftTeams.app.notifySuccess();
        //     });
        // }
        // });
    };

    onLoadInfoCallback = (success: string, error: string) => {
        if (error) {
            microsoftTeams.app.notifySuccess();
            this.setState({
                error: true
            });
        }
    };

    async signInWithBigtincan() {
        this.setState({
            isLoading: true
        })
        const queryString = window.location.search;
        const context = await microsoftTeams.app.getContext();
        const tenantId = context.user?.tenant?.id as string;
        const azureId = context.user?.id as string;
        const urlParams = new URLSearchParams(queryString);
        const defaultRegion = urlParams.get('region') as string;
        const filePermId = urlParams.get('permFileId') as string;
        const fileId = urlParams.get('fileId') as string;

        authentication.authenticate({
            url: `${window.location.origin}/app/auth-start-bigtincan?region=${defaultRegion}`,
            height: 800,
            width: 600,
        }).then(async (result: any) => {
            let data = JSON.parse(result);
            if (data) {
                let authData = data.authData;
                let userInfo = data.userInfo;
                let idAccessToken = authData.idAccessToken
                localStorage.setItem("BTC", JSON.stringify(authData));
                const { type, filePreviewLink }: any = await hubService.getPreviewLink(idAccessToken, tenantId, azureId, fileId, filePermId).catch((error: any) => {
                    if (error) {
                        microsoftTeams.app.notifySuccess();
                        this.setState({
                            error: true
                        });
                    }
                });
                if (type && filePreviewLink) {
                    microsoftTeams.app.notifySuccess();
                    this.setState({
                        type,
                        filePreviewLink,
                        error: false,
                        authError: false,
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    displayAuthError: true,
                    isLoading: false,
                    authError: true
                }, () => this.hideAlert());
            }
        }).catch((reason) => {
            this.setState({
                displayAuthError: true,
                isLoading: false,
                authError: true
            }, () => this.hideAlert());
        });
    }

    async hideAlert() {
        setTimeout(() => {
            this.setState({
                displayAuthError: false,
            });
        }, 3000);
    }

    handleInteraction = (event: any) => {
        if (event.target.id === "refreshButton") {
            window.location.reload();
        } else if (event.target === "signInWithBigtincan") {
            this.signInWithBigtincan();
        }
    };

    render() {
        const { authError, type, filePreviewLink, error } = this.state;

        const errorCommFields: ICommunicationFields = {
            title: `Something went wrong.`,
            desc: `Preview unavailable. Please try again.`,
        };

        const authErrorCommFields: ICommunicationFields = {
            title: `Looks like you are not signed-in.`,
            desc: ` Please sign-in and try again.`,
            actions: {
                primary: {
                    label: "Sign In with Bigtincan",
                    target: "signInWithBigtincan"
                },
            }
        };

        if (this.state.isLoading) {
            return (
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed"
                }}>
                    <Loader />
                </div>)
        }

        if (!authError && !error && type && filePreviewLink) {
            return (
                <div style={{ width: "100vh", height: "100vh", alignSelf: "center" }}>
                    <UnifiedContentPlayer
                        contentType={type}
                        sourcePath={filePreviewLink}
                        websocketUrl='wss://interactive-slides-api-int.clearslideng.com'
                        role='presenter'
                        thumbnail=''
                        isAudio={false}
                        autoplay={false}
                        onLoadInfoCallback={this.onLoadInfoCallback}
                    />
                </div>
            );
        } else {
            if (error && !type && !filePreviewLink) {
                return (
                    <div style={{ width: "100vh", height: "100vh", alignSelf: "center" }}>
                        <Communication
                            option={CommunicationOptions.Empty}
                            fields={errorCommFields}
                            onInteraction={this.handleInteraction}
                        />
                    </div>
                );
            } else if (authError && !type && !filePreviewLink) {
                return (
                    <div style={{ width: "100vh", height: "100vh", alignSelf: "center" }}>
                        <Communication
                            option={CommunicationOptions.Empty}
                            fields={authErrorCommFields}
                            onInteraction={this.handleInteraction}
                        />
                        {
                            this.state.displayAuthError &&
                            <div>
                                <AlertError />
                            </div>
                        }
                    </div>
                );
            }
        }
    }
}