/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Avatar, Divider } from '@fluentui/react-components';
import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuDivider,
    MenuPopover,
} from "@fluentui/react-components";
import { hubService } from '../hubService';
import * as microsoftTeams from '@microsoft/teams-js';

const moment = require("moment");

interface IHeaderProps {
    isSignedIn: boolean,
    onSignOutClick: any
}

const Header: React.FC<IHeaderProps> = ({ isSignedIn, onSignOutClick }) => {
    const [userName, setUserName] = useState("");
    const [theme, setTheme] = useState("");

    useEffect(() => {
        const fetchContext = async () => {
            let context = await microsoftTeams.app.getContext();
            let theme = context.app.theme;

            setTheme(theme);
            const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
            const firstName = userInfo?.first_name;
            const lastName = userInfo?.last_name;
            const fullname = `${firstName} ${lastName}`
            setUserName(fullname)
        };

        fetchContext();
    }, [isSignedIn]);

    return (
        <div style={{ height: "70px" }}>
            <div style={{ height: "70px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "2px", borderColor: "#000000" }}>
                {theme === "dark" ?
                    <img style={{ width: "100px", height: "70px", marginLeft: "20px" }} src={`${window.location.origin}/assets/images/bigtincan.svg`}></img>
                    :
                    <img style={{ width: "100px", height: "70px", marginLeft: "20px" }} src={`${window.location.origin}/assets/images/bigtincanDark.svg`}></img>
                }
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Avatar style={{ marginRight: "20px" }} size={40} name={userName} />
                    </MenuTrigger>
                    <MenuPopover>
                        {isSignedIn ?
                            <MenuList>
                                <div style={{ display: "flex", flexDirection: 'row', padding: "5px", alignItems: "center" }}>
                                    <Avatar style={{ marginRight: "20px" }} size={40} name={userName} />
                                    <p>{userName}</p>
                                </div>
                                <MenuDivider />
                                <MenuItem onClick={onSignOutClick}>Sign Out</MenuItem>
                            </MenuList>
                            :
                            <MenuList>
                                <div style={{ display: "flex", flexDirection: 'row', padding: "5px", alignItems: "center", width: "200px" }}>
                                    <p>Looks like you are not signed-in, please sign-in below to use the app</p>
                                </div>
                            </MenuList>
                        }
                    </MenuPopover>
                </Menu>
            </div>
            <Divider></Divider>
        </div>
    );
};

export default Header;
