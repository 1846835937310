/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import { ActionMenu, Button, Glyph, NavButton, } from "@bigtincan/react-design-system";
import React, { Component } from "react";

interface INavigationProps {
    onNavClick: Function,
    activeView: string
}

interface INavigationState {

}

class Navigation extends Component<INavigationProps, INavigationState> {
    constructor(props: any) {
        super(props);
        this.state = {

        };
    }

    render() {

        const { onNavClick } = this.props;

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                justifyContent: 'flex-start',
                padding: '12px',
                minHeight: "100%",
                width: '60px',
                borderRight: "1px solid rgba(0, 0, 0, .1)"
            }}>
                {/* <NavButton id="1" active={this.props.activeView === "Dashboard"} onClick={() => onNavClick('Dashboard')} fullSize={false} text='Dashboard'>
                    <Glyph.House24 />
                </NavButton> */}
                <NavButton id="4" active={this.props.activeView === "Tenants"} onClick={() => onNavClick('Tenants')} fullSize={false} text='Tenants'>
                    <Glyph.Podium24 />
                </NavButton>
                <NavButton id="2" active={this.props.activeView === "Installation logs"} onClick={() => onNavClick('Installation logs')} fullSize={false} text='Installation logs'>
                    <Glyph.Database24 />
                </NavButton>
                <NavButton id="3" active={this.props.activeView === "User Auth logs"} onClick={() => onNavClick('User Auth logs')} fullSize={false} text='UserAuth logs'>
                    <Glyph.BookPerson24 />
                </NavButton>
            </div>
        );
    }
}

export default Navigation;