/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import { Spinner, Table, TableCell, TableHeaderCell, TableHeaderRow, TableRow } from "@bigtincan/react-design-system";
import React, { useEffect, useState } from "react";

interface IUserAuthTableProps {
    rows?: any;
    azureIdSearch?: string;
    tenantIdSearch?: string;
    isLoading: boolean;
    showDeleted?: boolean;
}

const UserAuthTableComponent: React.FunctionComponent<IUserAuthTableProps> = ({ showDeleted, isLoading, rows }) => {

    const [filteredRows, setFilteredRows] = useState([]);
    const [sortDirection, setSortDirection] = useState('ascending');

    useEffect(() => {

        const filterAndSort = () => {
            let result = rows.rows && rows.rows.length > 0 ? rows.rows : [];

            result.sort((a: any, b: any) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return sortDirection === 'ascending' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
            });

            return result;
        };

        setFilteredRows(filterAndSort());
    }, [rows, showDeleted, sortDirection]);

    return (
        <>
            <Table style={{ overflow: "auto" }}>
                <tbody>
                    <TableHeaderRow>
                        <TableHeaderCell align="center" title='ID' />
                        <TableHeaderCell align="center" title='Microsoft Tenant ID' />
                        <TableHeaderCell align="center" title='Microsoft User ID (Azure ID)' />
                        <TableHeaderCell align="center" title='BTC Hub User ID' />
                        <TableHeaderCell align="center" title='BTC Username' />
                        <TableHeaderCell align="center" title='BTC Region' />
                        <TableHeaderCell initialSortDirection='ascending' onSortChange={(args: any) => setSortDirection(args.sortDirection)} isSortable={true} isSorted={true} align="center" title='Created At' />
                        <TableHeaderCell align="center" title='Updated At' />
                        <TableHeaderCell align="center" title='Deleted' />
                    </TableHeaderRow>
                    {filteredRows && filteredRows.length > 0 && !isLoading &&
                        filteredRows.map((row: any, index: any) => (
                            <TableRow key={row.id}>
                                <TableCell align="center">{row.id}</TableCell>
                                <TableCell align="center">{row.tenantId}</TableCell>
                                <TableCell align="center">{row.azureId}</TableCell>
                                <TableCell align="center">{row.btcUserId}</TableCell>
                                <TableCell align="center">{row.btcUserEmail}</TableCell>
                                <TableCell align="center">{row.btcRegion}</TableCell>
                                <TableCell align="center">{row.createdAt}</TableCell>
                                <TableCell align="center">{row.updatedAt}</TableCell>
                                <TableCell align="center">{row.deleted ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        ))
                    }
                    {isLoading &&
                        <TableRow>
                            <TableCell align="center" colSpan={9}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Spinner size='large' />
                                </div>
                            </TableCell>
                        </TableRow>
                    }
                </tbody>
            </Table>
        </>
    );
}

export default UserAuthTableComponent;