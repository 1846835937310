import { ActionMenu, Avatar, Button, TextField, TopBar } from "@bigtincan/react-design-system";
import React from "react";

interface ITopbarProps {
    userAuthenticated: boolean;
    firstName: string;
    lastName: string;
}

const Topbar: React.FC<ITopbarProps> = ({ userAuthenticated, firstName, lastName }) => {

    const getInitials = (firstName: string, lastName: string): string => {
        const firstInitial = firstName.charAt(0).toUpperCase();
        const lastInitial = lastName.charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
    };

    const handleSignOut = () => {
        localStorage.clear();
        window.location.href = "/admin/"
    }

    return (
        <TopBar
            leftSection={
                <>
                    <Button
                        aria-label='Home'
                        hierarchy='plain'
                        onClick={() => console.log('Home button clicked')}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '32px',
                        }}
                    >
                        <img
                            src={`${window.location.origin}/assets/images/bigtincanDark.svg`}
                            style={{
                                height: 'inherit'
                            }}
                        />
                        <p
                            style={{
                                borderLeft: '1px solid',
                                borderColor: 'hsla(0, 0%, 80%, 1)',
                                color: 'hsla(0, 0%, 40%, 1)',
                                fontSize: '16px',
                                marginLeft: '16px',
                                paddingLeft: '16px'
                            }}
                        >
                            Teams app Admin Portal
                        </p>
                    </Button>
                </>
            }
            rightSection={
                <div style={{ alignItems: 'center', display: 'flex' }}>
                    {userAuthenticated && (
                        <>

                            <ActionMenu>
                                <ActionMenu.Root>
                                    <ActionMenu.Trigger hierarchy='plain'>
                                        <Avatar
                                            aria-label='Profile'
                                            size={32}
                                            initials={getInitials(firstName, lastName)}
                                        />
                                    </ActionMenu.Trigger>
                                    <ActionMenu.Content menuType='main'>
                                        <ActionMenu.Item aria-labelledby='signout' onClick={handleSignOut}>Sign out</ActionMenu.Item>
                                    </ActionMenu.Content>
                                </ActionMenu.Root>
                            </ActionMenu>
                        </>
                    )}
                </div>
            }
        />
    );
};

export default Topbar;
