/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React, { useState, useEffect } from 'react';
import InstallationsTableComponent from '../../components/InstallationsTableComponent';
import { Checkbox, EXPERIMENTAL_Pagination, TextField } from '@bigtincan/react-design-system';
import { hubService } from '../../hubService';

const Installations: React.FC<{}> = ({ }) => {
    const [rows, setRows] = useState<any>([]);
    const [showDeleted, setShowDeleted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tenantSearchTerm, setTenantSearchTerm] = useState<string>('');
    const [azureSearchTerm, setAzureSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            hubService.getInstallationData(undefined, tenantSearchTerm, azureSearchTerm, showDeleted, limit, offset).then((data: any) => {
                setRows(data.rows);
                setTotalCount(data.totalCount)
                setIsLoading(false);
            });
        };

        fetchData();
    }, [tenantSearchTerm, azureSearchTerm, showDeleted, currentPage]);

    const handlePageChange = (newPage: any) => {
        let newOffset = limit * (newPage - 1);

        setCurrentPage(newPage);
        setOffset(newOffset);
    };

    const handleShowDeleted = () => {
        setShowDeleted(!showDeleted);
    };

    const handleTenantSearchChange = (value: string) => {
        setTenantSearchTerm(value);
    };

    const handleAzureSearchChange = (value: string) => {
        setAzureSearchTerm(value);
    };

    return (
        <div style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            overflow: "auto"
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "20px",
                paddingRight: "40px",
            }}>
                <h1>App Installations</h1>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <TextField
                        placeholder='Search Tenant ID'
                        variant='solid'
                        value={tenantSearchTerm}
                        onChange={handleTenantSearchChange}
                        style={{ marginRight: '16px', width: '360px' }}
                    />
                    <TextField
                        placeholder='Search User ID (Azure ID)'
                        variant='solid'
                        value={azureSearchTerm}
                        onChange={handleAzureSearchChange}
                        style={{ marginRight: '16px', width: '360px' }}
                    />
                    <Checkbox
                        label="Show deleted"
                        onChange={handleShowDeleted}
                        checked={showDeleted}
                        size="large"
                    />
                </div>
            </div>
            <div style={{ paddingRight: "40px", paddingLeft: "20px", overflow: "auto" }}>
                <InstallationsTableComponent
                    isLoading={isLoading}
                    showDeleted={showDeleted}
                    rows={rows}
                />
            </div>
            {totalCount > 1 && (
                <EXPERIMENTAL_Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    selected={itemsPerPage}
                    variant="outline"
                />
            )}
        </div>
    );
};

export default Installations;
