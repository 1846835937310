/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import { Table, TableCell, TableHeaderCell, HeaderActions, TableHeaderRow, TableRow, Button, Dialog, TextField, useDialog, Subheader, TextArea, Divider, ActionMenu, Icon, useTrapFocus, EXPERIMENTAL_Pagination, Spinner } from "@bigtincan/react-design-system";
import React, { useEffect, useState } from "react";
import { hubService } from "../../hubService";
import InstallationsTableComponent from "../InstallationsTableComponent";
import TenantSettingsDialog from "../TenantSettingsDialog";
import UserAuthTableComponent from "../userAuthTableComponent";

interface ITenantsTableComponentProps {
    rows: any[];
    isLoading: boolean;
}

const TenantsTableComponent: React.FunctionComponent<ITenantsTableComponentProps> = ({ rows, isLoading }) => {
    const [dialogRows, setDialogRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState<any>([]);
    const [tenantSettingsDialogOpen, setTenantSettingsDialogOpen] = useState<boolean>(false);
    const [installationsDialogOpen, setInstallationsDialogOpen] = useState<boolean>(false);
    const [usersDialogOpen, setUsersDialogOpen] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [selectedRowSetting, setSelectedRowSetting] = useState<any>();

    useEffect(() => {
        const filterAndSort = () => {
            if (rows && rows.length > 0) {
                return rows;
            } else {
                return []
            }
        };

        setFilteredRows(filterAndSort());
    }, [rows]);

    const handleSave = (updatedSettings: any) => {
        hubService.editTenantSettings(selectedRow.tenantId, updatedSettings);
    };

    const handleCancel = () => {
        setTenantSettingsDialogOpen(false);
    };

    const handleShowInstallations = (row: any) => {
        const tenantId = row.tenantId;
        setInstallationsDialogOpen(true);
        setUsersDialogOpen(false);

        hubService.getInstallationData(tenantId).then((response: any) => {
            setDialogRows(response);
        });
    };

    const handleShowUsers = (row: any) => {
        const tenantId = row.tenantId;
        setUsersDialogOpen(true);
        setInstallationsDialogOpen(false);

        hubService.getUserAuthData(tenantId).then((response: any) => {
            setDialogRows(response);
        });
    };

    const handleEditSettingsClick = (row: any) => {
        const tenantId = row.tenantId;
        hubService.getTenantSettingsForAdmin(tenantId).then((response: any) => {
            setSelectedRowSetting(response);
            setTenantSettingsDialogOpen(true);
        });
    };

    return (
        <>
            <Table style={{ overflow: "auto" }}>
                <tbody>
                    <TableHeaderRow>
                        <TableHeaderCell align="center" title='Tenant ID' />
                        <TableHeaderCell align="center" title='User count' />
                        <TableHeaderCell align="center" title='Settings' />
                        <TableHeaderCell align="center" title='Actions' />
                    </TableHeaderRow>
                    {filteredRows.map((row: any, index: number) => (
                        <TableRow key={index}>
                            <TableCell align="center">{row.tenantId || "N/A"}</TableCell>
                            <TableCell align="center">{row.userCount || "0"}</TableCell>
                            <TableCell align="center">{row.tenantSettings ? Object.keys(row.tenantSettings).join(", ") : "N/A"}</TableCell>
                            <TableCell align="center">
                                <ActionMenu>
                                    <ActionMenu.Root>
                                        <ActionMenu.Trigger aria-label='Actions'>
                                            <Icon.More24 />
                                        </ActionMenu.Trigger>
                                        <ActionMenu.Content>
                                            <ActionMenu.Item onClick={() => {
                                                setSelectedRow(row);
                                                handleEditSettingsClick(row);
                                            }}>
                                                Edit settings
                                            </ActionMenu.Item>
                                            <ActionMenu.Item onClick={() => {
                                                setSelectedRow(row);
                                                handleShowUsers(row);
                                            }}>
                                                Show Users
                                            </ActionMenu.Item>
                                            <ActionMenu.Item onClick={() => {
                                                setSelectedRow(row);
                                                handleShowInstallations(row);
                                            }}>
                                                Show Installations
                                            </ActionMenu.Item>
                                        </ActionMenu.Content>
                                    </ActionMenu.Root>
                                </ActionMenu>
                            </TableCell>
                        </TableRow>
                    ))}
                    {isLoading && (
                        <TableRow>
                            <TableCell align="center" colSpan={4}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Spinner size='large' />
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                </tbody>
                <TenantSettingsDialog settings={selectedRowSetting} isOpen={tenantSettingsDialogOpen} onSave={handleSave} onCancel={handleCancel} />
                <Dialog isOpen={installationsDialogOpen} onXClick={() => setInstallationsDialogOpen(false)} size="xlarge">
                    <Dialog.Header>Installations for Tenant ID</Dialog.Header>
                    <Dialog.Body>
                        <InstallationsTableComponent isLoading={isLoading} rows={dialogRows} />
                    </Dialog.Body>
                </Dialog>
                <Dialog isOpen={usersDialogOpen} onXClick={() => setUsersDialogOpen(false)} size="xlarge">
                    <Dialog.Header>Users for Tenant ID</Dialog.Header>
                    <Dialog.Body>
                        <UserAuthTableComponent isLoading={isLoading} rows={dialogRows} />
                    </Dialog.Body>
                </Dialog>
            </Table>
        </>
    );
};

export default TenantsTableComponent;
