/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import { Icon, Tooltip } from "@bigtincan/react-design-system";

interface ITooltipProps {
    settingKey: string;
}

const SettingTooltip: React.FunctionComponent<ITooltipProps> = ({ settingKey }) => {

    const settingInfo = {
        BTC_REGION: 'Default region for the tenant.',
        APP_ID: 'Enter the APP ID obtained from the Teams Admin Center. This allows the app to generate Stage View links within the application.',
        CUSTOMER_CODE: 'Unique code to identify the customer.',
        SSO_ID: 'ID used for SSO authentication.',
        GENIE_AI_ENABLED: 'Activates SearchAI by default for all users in a tenant.',
        PREVIEW_ENABLED: 'Enables content preview by default for all users in a tenant.',
        DEBUG_OPTIONS: 'Recommended value: "SEARCH". Logs the search query being passed to the backend. (For troubleshooting purposes)'
    } as any

    return (
        <Tooltip id={`hover-text-${settingKey}`} text={settingInfo[settingKey]} >
            <Icon.Info16 />
        </Tooltip>
    );
}

export default SettingTooltip;
