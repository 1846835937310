import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import Configure from './containers/Configure';
import { FluentProvider } from '@fluentui/react-components';
import { teamsLightTheme, teamsDarkTheme } from '@fluentui/react-theme'; // Import Teams theme
import MeetingHome from './containers/MeetingHome';
import BigtincanConsent from './components/BigtincanConsent';
import BigtincanClosePopup from './components/BigtincanClosePopup';
import { Login } from './components/Login';
import { themeNames } from "@fluentui/react-teams";
import { Provider } from "@fluentui/react-teams";
import FileViewer from './components/FileViewer';
import Share from './components/Share';
import Home from './containers/Home';
import AdminPortal from './containers/Admin';
import { AppProvider } from "@bigtincan/react-design-system";
import Admin from './containers/Admin';
import Installations from './containers/Installations';
import AdminAuthSuccess from './components/AdminAuthSuccess';
import AdminAuthFail from './components/AdminAuthFail';

// import ConsentPopup from './components/ConsentPopup';
// import ClosePopup from './components/ClosePopup';

export const AppRoute = () => {
  const [appAppearance, setAppAppearance] = React.useState(themeNames.Default);
  const [appTheme, setAppTheme] = React.useState(teamsLightTheme);

  React.useEffect(() => {
    microsoftTeams.app
      .initialize()
      .then(() => {
        microsoftTeams.app.notifyAppLoaded();
        microsoftTeams.app.notifySuccess();
        microsoftTeams.app.getContext().then((context) => {
          setAppAppearance(context.app.theme === 'default' ? themeNames.Default : themeNames.Dark);
          setAppTheme(context.app.theme === 'default' ? teamsLightTheme : teamsDarkTheme);
        });
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <Provider themeName={appAppearance} lang="en-US"> {/* Apply Teams theme */}
        <BrowserRouter basename='/app'>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/auth-start-bigtincan" element={<BigtincanConsent />} />
            <Route path="/bigtincan-auth-end" element={<BigtincanClosePopup />} />
            <Route path="/fileviewer" element={<FileViewer />} />
            <Route path="/share" element={<Share />} />
            {/* <Route path="/auth-start/microsoft" element={<ConsentPopup />} /> */}
            {/* <Route path="/auth-end/micrososft" element={<ClosePopup />} /> */}
          </Routes>
        </BrowserRouter>
      </Provider>

      <FluentProvider theme={appTheme} lang="en-US">
        <BrowserRouter basename='/app'>
          <Routes>
            <Route path="/meeting/configure" element={<Configure />} />
            <Route path="/meeting/home" element={<MeetingHome />} />
          </Routes>
        </BrowserRouter>
      </FluentProvider>

      <AppProvider>
        <BrowserRouter >
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/auth-success" element={<AdminAuthSuccess />} />
            <Route path="/admin/auth-fail" element={<AdminAuthFail />} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </>
  );
};