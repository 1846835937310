/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import * as React from "react";
import {
    Button,
} from "@fluentui/react-components";
import {
    Caption1,
    Card,
    tokens,
    CardHeader,
    CardPreview
} from "@fluentui/react-components";
import {
    Checkmark24Filled,
    Add24Filled
} from "@fluentui/react-icons";
import * as microsoftTeams from "@microsoft/teams-js";
import { hubService } from "../hubService";

interface SearchFileProps {
    frameContext: string;
    selected: boolean;
    file: any;
    onClick: Function;
}

interface SearchFileState {
    file: any;
    isMobile: boolean;
    appId: string;
}

export class SearchFileResult extends React.Component<SearchFileProps, SearchFileState>  {
    constructor(props: any) {
        super(props);
        this.state = {
            file: this.props.file,
            isMobile: false,
            appId: ""
        };
    }

    async componentDidMount() {
        microsoftTeams.app.initialize().then(async () => {
            if (window.innerWidth <= 767) {
                this.setState({
                    isMobile: true
                })
            }

            let context = await microsoftTeams.app.getContext();
            let tenantId = context.user?.tenant?.id as string;
            let appId: string; // APP ID coming from Microsoft Admin portal, being used for stage view

            const tenantSettings = await hubService.getTenantSettings(tenantId) as any;
            if (tenantSettings) {
                if (tenantSettings["MICROSOFT_ADMIN_APP_ID"]) {
                    appId = tenantSettings["MICROSOFT_ADMIN_APP_ID"];
                    this.setState({
                        appId
                    });
                }
            }
        });
    }

    public render() {
        let frameContext = this.props.frameContext;
        let file = this.props.file;
        let fileExtension = file.filename.split('.').pop();
        let selected = this.props.selected;
        const defaultCoverArt = (fileExtension: string) => {
            switch (fileExtension) {
                case "usdz":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "btca":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "aac":
                case "aif":
                case "au":
                case "flac":
                case "m4a":
                case "mp3":
                case "ogg":
                case "wav":
                    return "https://btcicons.fatstaxapp.com/m/172986"
                case "btc":
                case "btcp":
                    return "https://btcicons.fatstaxapp.com/m/172998"
                case "btcn":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "dwg":
                case "dxf":
                    return "https://btcicons.fatstaxapp.com/m/172985"
                case "csv":
                    return "https://btcicons.fatstaxapp.com/m/172999"
                case "kml":
                case "kmz":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "ebook":
                case "mobi":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "epub":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "xls":
                case "xlsx":
                case "excel":
                    return "https://btcicons.fatstaxapp.com/m/172993"
                case "folder":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "btcf":
                    return "https://btcicons.fatstaxapp.com/m/172994"
                case "ibooks":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "bmp":
                case "gif":
                case "jpg":
                case "jpeg":
                case "png":
                case "svg":
                case "tiff":
                case "tif":
                case "image":
                    return "https://btcicons.fatstaxapp.com/m/172982"
                case "ipa":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "key":
                case "keynote":
                    return "https://btcicons.fatstaxapp.com/m/172991"
                case "btce":
                    return "https://btcicons.fatstaxapp.com/m/172995"
                case "btcd":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "numbers":
                    return "https://btcicons.fatstaxapp.com/m/172992"
                case "oomph":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "pages":
                    return "https://btcicons.fatstaxapp.com/m/172984"
                case ".pdf":
                case "pdf":
                    return "https://btcicons.fatstaxapp.com/m/173002"
                case "potx":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "pps":
                case "ppsx":
                case "ppt":
                case "pptx":
                case "powerpoint":
                    return "https://btcicons.fatstaxapp.com/m/173001"
                case "mpp":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "mobileprovision":
                case "mobileconfig":
                case "prov":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "rtf":
                    return "https://btcicons.fatstaxapp.com/m/172983"
                case "rtfd":
                    return "https://btcicons.fatstaxapp.com/m/173007"
                case "scrollmotiontransit":
                    return "https://btcicons.fatstaxapp.com/m/173007"
                case "twixl":
                    return "https://btcicons.fatstaxapp.com/m/173007"
                case "txt":
                case "text":
                    return "https://btcicons.fatstaxapp.com/m/172987"
                case "vcard":
                case "vcf":
                    return "https://btcicons.fatstaxapp.com/m/172918"
                case "3gpp":
                case "3gp":
                case "mp4":
                case "mpg":
                case "avi":
                case "wmv":
                case "mov":
                case "flv":
                case "m4v":
                case "mkv":
                case "video":
                    return "https://btcicons.fatstaxapp.com/m/173004"
                case "vsd":
                case "vsdx":
                case "vsx":
                case "vss":
                case "vtx":
                case "vdx":
                case "vdw":
                case "vst":
                    return "https://btcicons.fatstaxapp.com/m/172989"
                case "htm":
                case "html":
                case "web":
                    return "https://btcicons.fatstaxapp.com/m/172996"
                case "doc":
                case "docx":
                case "word":
                    return "https://btcicons.fatstaxapp.com/m/172997"
                case "tar.gz":
                case "tar":
                case "rar":
                case "zip":
                case "7z":
                    return "https://btcicons.fatstaxapp.com/m/172990"
                default:
                    return "https://btcicons.fatstaxapp.com/m/172934"
            }
        }

        const updateExcerpt = (excerpt: string) => {
            if (excerpt) {
                excerpt = excerpt.replace(/<mark>/g, "");
                excerpt = excerpt.replace(/<\/mark>/g, "");
            }
            return excerpt;
        }

        const handlePreviewFileClick = (event: any, file: any) => {
            let stageViewContext = encodeURIComponent(JSON.stringify({
                "contentUrl": `${window.location.origin}/app/fileViewer?permFileId=${file.file_perm_id}&fileId=${file.id}`,
                "websiteUrl": `${window.location.origin}/app/fileViewer?permFileId=${file.file_perm_id}&fileId=${file.id}`,
                "name": file.description
            }));

            microsoftTeams.app.openLink("https://teams.microsoft.com/l/stage/" + this.state.appId + `/0?context=${stageViewContext}`);
        }

        if (frameContext === "sidePanel") {
            return (
                <Card style={{
                    width: "100%",
                    height: "90px",
                    alignItems: "center"
                }}
                    orientation="horizontal"
                >
                    <CardPreview style={{
                        width: "64px",
                        height: "64px",
                        paddingTop: "10px"
                    }}>
                        <img
                            style={{
                                width: "64px",
                                height: "64px"
                            }}
                            src={file.cover_art?.url || defaultCoverArt(fileExtension)}
                            alt="App Name Document"
                        />
                    </CardPreview>

                    <CardHeader
                        style={{ width: "100%", flex: 1, flexWrap: "wrap", flexDirection: "column" }}
                        header={file.description}
                        action={
                            <>
                                {selected == true ?
                                    <Button
                                        appearance="transparent"
                                        size="small"
                                        icon={<Checkmark24Filled />}
                                        aria-label="Add"
                                        disabled={true}
                                        onClick={(event: any) => this.props.onClick(event, file)}
                                    /> :
                                    <>
                                        <Button
                                            appearance="transparent"
                                            size="small"
                                            icon={<Add24Filled />}
                                            aria-label="Add"
                                            onClick={(event: any) => this.props.onClick(event, file)}
                                        />
                                    </>
                                }
                            </>
                        }
                    />
                </Card >
            )
        } else {
            return (
                this.state.isMobile ?
                    <Card
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <CardPreview
                                style={{ backgroundColor: tokens.colorNeutralBackground3, width: "100px", height: "80px" }}
                            >
                                <img
                                    src={file.cover_art?.url || defaultCoverArt(fileExtension)}
                                    alt={file.description}
                                />
                            </CardPreview>
                            <div
                                style={{ flex: 1, padding: "10px" }}
                            >
                                {file.description}
                                <div>
                                    <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>{`Found In: ${file.tab.tab_name} > ${file.story.channel.name} > ${file.story.title}`}</Caption1><br></br>
                                    <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>{updateExcerpt(file.excerpt)}</Caption1>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                padding: "10px"
                            }}
                        >
                            {selected === true ?
                                <Button disabled={true} style={{ marginRight: "10px" }} onClick={(event: any) => this.props.onClick(event, file)} appearance="secondary">
                                    Added
                                </Button> :
                                <Button style={{ marginRight: "10px" }} onClick={(event: any) => this.props.onClick(event, file)} appearance="primary">
                                    Add
                                </Button>
                            }
                            <Button onClick={(event: any) => handlePreviewFileClick(event, file)}>
                                Preview
                            </Button>
                        </div>
                    </Card>
                    :
                    <Card
                        style={{
                            width: "100%",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "row"
                        }}
                    >
                        <CardPreview
                            style={{ backgroundColor: tokens.colorNeutralBackground3, width: "200px", height: "150px", alignSelf: "flex-start" }}
                        >
                            <img
                                src={file.cover_art?.url || defaultCoverArt(fileExtension)}
                                alt={file.description}
                            />
                        </CardPreview>

                        <CardHeader
                            style={{ flex: 1, height: "fit-content", padding: "10px", }}
                            header={file.description}
                            description={<div>
                                <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>{`Found In: ${file.tab.tab_name} > ${file.story.channel.name} > ${file.story.title}`}</Caption1><br></br>
                                <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>{updateExcerpt(file.excerpt)}</Caption1>
                            </div>
                            }
                            action={
                                <>
                                    {selected == true ?
                                        <Button disabled={true} style={{ marginBottom: "10px" }} onClick={(event: any) => this.props.onClick(event, file)} appearance="secondary">
                                            Added
                                        </Button> :
                                        <>
                                            <Button style={{ marginBottom: "10px" }} onClick={(event: any) => this.props.onClick(event, file)} appearance="primary">
                                                Add
                                            </Button>
                                        </>
                                    }
                                    <Button onClick={(event: any) => handlePreviewFileClick(event, file)}>
                                        Preview
                                    </Button>
                                </>
                            }
                        />
                    </Card >
            )
        }
    }
}